import { Formik, FormikHelpers } from "formik";
import jwtDecode from "jwt-decode";
import { FC, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import {
  GetContactPersonInput,
  useCreateContactPersonMutation,
  useGetAllUserQuery,
  useGetBranchQuery,
  useGetContactCategoryQuery,
  useGetStandardQuery,
} from "../../gql";
import { useAppState } from "../../store";

const initValue: GetContactPersonInput = {
  address: "",
  admissionConfirmed: false,
  assignedUser: "",
  cancleReasone: "",
  contact1: "",
  contact2: "",
  feeDeliverd: "",
  feePaid: "",
  isActive: false,
  name: "",
  preSchool: "",
  preTution: "",
  refrence: "",
  standard: "",
  branch: "",
  contactCategory: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string(),
  admissionConfirmed: Yup.boolean().oneOf([true, false]).required(),
  assignedUser: Yup.string().required(),
  cancleReasone: Yup.string(),
  contact1: Yup.string().min(10).max(10).required(),
  contact2: Yup.string().min(10).max(10),
  feeDeliverd: Yup.string(),
  feePaid: Yup.string(),
  isActive: Yup.boolean().oneOf([true, false]).required(),
  preSchool: Yup.string(),
  preTution: Yup.string(),
  refrence: Yup.string(),
  standard: Yup.string().required(),
  branch: Yup.string().required(),
  contactCategory: Yup.string().required(),
});

const Add = () => {
  const { data: standardData } = useGetStandardQuery();
  const { data: user } = useGetAllUserQuery();
  const { data: branch } = useGetBranchQuery();
  const { data: contactCategory } = useGetContactCategoryQuery();
  const [create] = useCreateContactPersonMutation();
  const { isAdmin, jwt } = useAppState();
  const [init, setInit] = useState({ empName: "" });

  useEffect(() => {
    if (isAdmin === false) {
      if (jwt) {
        const { _id } = jwtDecode(jwt) as { _id?: string };
        if (_id) {
          setInit({ empName: _id });
        }
      }
    }
  }, [isAdmin, jwt]);

  const handleSubmit = async (
    val: GetContactPersonInput,
    actions: FormikHelpers<GetContactPersonInput>
  ) => {
    actions.setSubmitting(true);

    try {
      const data = await create({ variables: { options: val } });

      if (data.data?.createContactPerson.isSuccess === true) {
        toast.success("Data is added successfully");
        // goBack();
      } else {
        toast.error("Trouble posting data");
      }
    } catch (err) {
      toast.error("Trouble posting data");
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <div>
            <h5>ADD CONVERSATION</h5>
          </div>
        </Card.Header>

        <Card.Body>
          <div>
            <Formik
              initialValues={initValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form onChange={handleChange} onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.FloatingLabel
                          label="Contact Category"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Select
                            size="sm"
                            name="contactCategory"
                            value={values.contactCategory}
                            isInvalid={
                              !!touched.contactCategory &&
                              !!errors.contactCategory
                            }
                          >
                            <option value="">select</option>
                            {Array.isArray(
                              contactCategory?.getContactCategory.data
                            ) &&
                              contactCategory?.getContactCategory.data
                                .filter((x) => x.isActive === true)
                                .map((x) => {
                                  return (
                                    <option value={x._id}>{x.name}</option>
                                  );
                                })}
                          </Form.Select>
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Name"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            value={values.name}
                            isInvalid={!!touched.name && !!errors.name}
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Address"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter address"
                            name="address"
                            value={values.address}
                            isInvalid={!!touched.address && !!errors.address}
                          />
                        </Form.FloatingLabel>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.FloatingLabel
                          label="Pre School"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter pre school"
                            name="preSchool"
                            value={values.preSchool}
                            isInvalid={
                              !!touched.preSchool && !!errors.preSchool
                            }
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Pre Tuition"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter pre tuition"
                            name="preTution"
                            value={values.preTution}
                            isInvalid={
                              !!touched.preTution && !!errors.preTution
                            }
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Contact 1"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="number"
                            maxLength={10}
                            placeholder="Enter contact 1"
                            name="contact1"
                            value={values.contact1}
                            isInvalid={!!touched.contact1 && !!errors.contact1}
                          />
                        </Form.FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.FloatingLabel
                          label="Contact 2"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="number"
                            maxLength={10}
                            placeholder="Enter contact 2"
                            name="contact2"
                            value={values.contact2}
                            isInvalid={!!touched.contact2 && !!errors.contact2}
                          />
                        </Form.FloatingLabel>
                      </Col>

                      <Col>
                        <Form.FloatingLabel
                          label="Branch"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Select
                            size="sm"
                            name="branch"
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value="">select</option>
                            {Array.isArray(branch?.getBranch.data) &&
                              branch?.getBranch.data
                                .filter((x) => x.isActive === true)
                                .map((x) => {
                                  return (
                                    <option value={x._id}>{x.name}</option>
                                  );
                                })}
                          </Form.Select>
                        </Form.FloatingLabel>
                      </Col>

                      <Col>
                        <Form.FloatingLabel
                          label="Standard"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Select
                            size="sm"
                            name="standard"
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value="">select</option>
                            {Array.isArray(standardData?.getStandard.data) &&
                              standardData?.getStandard.data
                                .filter((x) => x.isActive === true)
                                .map((x) => {
                                  return (
                                    <option value={x._id}>{x.name}</option>
                                  );
                                })}
                          </Form.Select>
                        </Form.FloatingLabel>
                      </Col>
                    </Row>

                    <Row>
                      <CustComp
                        value={init.empName}
                        setFieldValue={setFieldValue}
                        name={"assignedUser"}
                      />

                      <Col>
                        <Form.FloatingLabel
                          label="Assign Employee"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Select
                            size="sm"
                            name="assignedUser"
                            value={values.assignedUser}
                            isInvalid={
                              !!touched.assignedUser && !!errors.assignedUser
                            }
                            disabled={!isAdmin}
                            defaultValue={!isAdmin ? init.empName : ""}
                          >
                            <option value="">select</option>

                            {Array.isArray(user?.getAllUser.data) &&
                              user?.getAllUser.data
                                .filter((x) => x.isActive === true)
                                .filter((x) => x.isAdmin !== true)
                                .map((x) => {
                                  return (
                                    <option value={x._id}>
                                      {x.userName} - {x.email}
                                    </option>
                                  );
                                })}
                          </Form.Select>
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Fees Delivered"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter fees delivered"
                            name="feeDeliverd"
                            value={values.feeDeliverd}
                            isInvalid={
                              !!touched.feeDeliverd && !!errors.feeDeliverd
                            }
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Fees Paid"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Fees paid"
                            name="feePaid"
                            value={values.feePaid}
                            isInvalid={!!touched.feePaid && !!errors.feePaid}
                          />
                        </Form.FloatingLabel>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.FloatingLabel
                          label="Reference"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter reference"
                            name="refrence"
                            value={values.refrence}
                            isInvalid={!!touched.refrence && !!errors.refrence}
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.FloatingLabel
                          label="Cancel Reason"
                          controlId="floatingInput"
                          className="mb-3"
                        >
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter reason"
                            name="cancleReasone"
                            value={values.cancleReasone}
                            isInvalid={
                              !!touched.cancleReasone && !!errors.cancleReasone
                            }
                          />
                        </Form.FloatingLabel>
                      </Col>
                      <Col>
                        <Form.Check
                          checked={values.admissionConfirmed}
                          label="Is Admission Confirmed"
                          onClick={() =>
                            setFieldValue(
                              "admissionConfirmed",
                              !values.admissionConfirmed
                            )
                          }
                        />

                        <Form.Check
                          checked={values.isActive}
                          label="Is Closed"
                          onClick={() =>
                            setFieldValue("isActive", !values.isActive)
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <Button type="submit" disabled={isSubmitting}>
                            ADD
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const CustComp: FC<{
  name: string;
  setFieldValue: (arg0: string, arg1: string) => void;
  value: string;
}> = ({ name, setFieldValue, value }) => {
  useEffect(() => {
    if (value) {
      setFieldValue(name, value);
    }
  }, [value]);
  return <></>;
};

export default Add;
