import { useToggle } from "@react-hookz/web";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  Navbar,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { UpdatePasswrodInput, useUpdatePasswordMutation } from "../gql";
import { useAppState } from "../store";

const initValue: UpdatePasswrodInput = {
  newPassword: "",
  oldPassword: "",
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().min(6).max(25).required(),
  oldPassword: Yup.string().min(6).max(25).required(),
});

const RenderChangePasswordForm: React.FC<{
  isToggled: boolean;
  toggle: () => void;
}> = ({ isToggled, toggle }) => {
  const [create] = useUpdatePasswordMutation();

  const handleSubmit = async (
    val: UpdatePasswrodInput,
    actions: FormikHelpers<UpdatePasswrodInput>
  ) => {
    console.log("WHY???");
    actions.setSubmitting(true);
    try {
      const data = await create({
        variables: {
          options: {
            newPassword: val.newPassword,
            oldPassword: val.oldPassword,
          },
        },
      });

      if (data.data?.updatePassword.isSuccess === true) {
        toast.success("Password updated successfully");
        toggle();
      } else {
        toast.error("Password does not match");
      }
    } catch (err) {
      toast.error("Trouble updating password");
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Modal show={isToggled} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting,
          }) => {
            return (
              <>
                <Form onChange={handleChange} onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        name="oldPassword"
                        value={values.oldPassword}
                        isInvalid={
                          !!touched.oldPassword && !!errors.oldPassword
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        name="newPassword"
                        value={values.newPassword}
                        isInvalid={
                          !!touched.newPassword && !!errors.newPassword
                        }
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={toggle}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const AppNavbar = () => {
  const { username, logout, isAdmin } = useAppState();
  const [isToggled, toggle] = useToggle(false);

  return (
    <Navbar bg="dark" variant="dark" className="mb-3" expand="md">
      <RenderChangePasswordForm isToggled={isToggled} toggle={toggle} />
      <Container>
        <Navbar.Brand as={Link} to="/dashboard">
          <img
            src="http://scpledu.com/assets/images/logo/logo.png"
            style={{ height: "3rem" }}
            alt="not found"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/dashboard">
            Dashboard
          </Nav.Link>
          {isAdmin === true && (
            <Nav.Link as={Link} to="/admin/employee">
              Employee
            </Nav.Link>
          )}
          {isAdmin === true && (
            <Nav.Link as={Link} to="/admin/branch">
              Branch
            </Nav.Link>
          )}
          {isAdmin === true && (
            <Nav.Link as={Link} to="/admin/standard">
              Standard
            </Nav.Link>
          )}

          {isAdmin === true && (
            <Nav.Link as={Link} to="/admin/contact/category">
              Contact Category
            </Nav.Link>
          )}

          <Nav.Link as={Link} to="/admin/conversation">
            Conversation
          </Nav.Link>
        </Nav>
        <Nav className="ms-auto">
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdown-basic">
              {"Hello " + username + " "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={toggle}>Change Password</Dropdown.Item>
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
