import { useToggle } from "@react-hookz/web";
import { Formik, FormikHelpers } from "formik";
import { useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { CustTable } from "../../../Componenet/CustTable";
import Loader from "../../../Componenet/Loader";
import {
  CreateContactCategory,
  useCreateContactCategoryMutation,
  useDeleteContactCategoryMutation,
  useGetContactCategoryQuery,
  useUpdateContactCategoryMutation,
} from "../../../gql";

const initialValue: CreateContactCategory = {
  isActive: true,
  name: "",
};

const validationSchema = Yup.object().shape({
  isActive: Yup.boolean().oneOf([true, false]).required(),
  name: Yup.string().required(),
});

const AddContactCategory: React.FC<{
  isToggled: boolean;
  toggle: () => void;
  refetch: () => void;
}> = ({ isToggled, toggle, refetch }) => {
  const [create] = useCreateContactCategoryMutation();

  const handleSubmit = async (
    val: CreateContactCategory,
    actions: FormikHelpers<CreateContactCategory>
  ) => {
    actions.setSubmitting(true);

    try {
      await create({ variables: { options: val } });
      refetch();
      actions.resetForm();
      toast.success("Data added successfully");
      toggle();
    } catch (err) {
      toast.error("Trouble posting data");
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal show={isToggled} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Add Contact Category</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Form.FloatingLabel
                    label="Contact Category"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter standard name"
                      name="name"
                      value={values.name}
                      isInvalid={!!touched.name && !!errors.name}
                    />
                  </Form.FloatingLabel>

                  <Form.Check
                    label="isActive"
                    checked={values.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    onChange={() => {
                      setFieldValue("isActive", !values.isActive);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggle}>
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const EditStandard: React.FC<{
  isToggled: boolean;
  toggle: () => void;
  refetch: () => void;
  data: CreateContactCategory;
  _id: string;
}> = ({ isToggled, toggle, refetch, data, _id }) => {
  const [update] = useUpdateContactCategoryMutation();

  const handleSubmit = async (
    val: CreateContactCategory,
    actions: FormikHelpers<CreateContactCategory>
  ) => {
    actions.setSubmitting(true);

    try {
      await update({
        variables: { options: { _id, isActive: val.isActive, name: val.name } },
      });
      refetch();
      actions.resetForm();
      toast.success("Data updated successfully");
      toggle();
    } catch (err) {
      toast.error("Trouble updating data");
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal show={isToggled} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Branch</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Form.FloatingLabel
                    label="Branch"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter standard name"
                      name="name"
                      value={values.name}
                      isInvalid={!!touched.name && !!errors.name}
                    />
                  </Form.FloatingLabel>

                  <Form.Check
                    label="isActive"
                    checked={values.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    onChange={() => {
                      setFieldValue("isActive", !values.isActive);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggle}>
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const ManageActions: React.FC<{
  value: string;
  refetch: () => void;
  val: CreateContactCategory;
}> = ({ value, refetch, val }) => {
  const [isToggled, toggle] = useToggle(false);
  const [deleteQuery] = useDeleteContactCategoryMutation();

  const handleDelete = async () => {
    await deleteQuery({ variables: { options: { _id: value } } })
      .then((res) => {
        if (res.data?.deleteContactCategory.isSuccess) {
          toast.success(res.data.deleteContactCategory.msg);
        } else {
          toast.error(res.data?.deleteContactCategory.msg || "");
        }
      })
      .catch((err) => toast.error(err?.message || ""));

    refetch();
  };

  return (
    <>
      <EditStandard
        isToggled={isToggled}
        refetch={refetch}
        toggle={toggle}
        data={val}
        _id={value}
      />
      <div className="d-flex justify-content-center gap-2">
        <Button className="rounded-pill" onClick={toggle}>
          <i className="bi bi-pencil"></i>
        </Button>
        <Button
          className="rounded-pill"
          variant="danger"
          onClick={handleDelete}
        >
          <i className="bi bi-trash"></i>
        </Button>
      </div>
    </>
  );
};

const ContactCategory = () => {
  const [isToggled, toggle] = useToggle(false);
  const { loading, refetch, data } = useGetContactCategoryQuery();

  const columns = useMemo(
    () => [
      { Header: "No", accessor: "id" },
      { Header: "Standard", accessor: "name" },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: (e: any) => {
          return <span>{e.value === true ? "active" : "disable"}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: (e: any) => {
          return (
            <ManageActions
              value={e.value as string}
              refetch={refetch}
              val={e.row.original}
            />
          );
        },
      },
    ],
    []
  );

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div>
      <AddContactCategory
        toggle={toggle}
        isToggled={isToggled}
        refetch={refetch}
      />
      <div className="d-flex align-items-center justify-content-between">
        <h4>ContactCategory</h4>
        <Button onClick={toggle}>Add</Button>
      </div>
      {Array.isArray(data?.getContactCategory.data) && (
        <CustTable
          columns={columns}
          data={data?.getContactCategory.data.map((x, i) => ({
            ...x,
            id: i + 1,
          }))}
        />
      )}
    </div>
  );
};

export default ContactCategory;
