import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AllContactResponse = {
  __typename?: 'AllContactResponse';
  data: Array<ContactPerson>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type AllUserResponse = {
  __typename?: 'AllUserResponse';
  data?: Maybe<Array<User>>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  data: ObjectResponseData;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type Branch = {
  __typename?: 'Branch';
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type BranchByIdResponse = {
  __typename?: 'BranchByIdResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type Chat = {
  __typename?: 'Chat';
  Conversation: Conversation;
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  message: Scalars['String'];
};

export type ChatInput = {
  conversationId: Scalars['String'];
  message: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['String'];
  assignedEmployee: User;
  isActive: Scalars['Boolean'];
  isAdmissionConfirmed: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ContactByIdResponse = {
  __typename?: 'ContactByIdResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type ContactCategory = {
  __typename?: 'ContactCategory';
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ContactCategoryByIdResponse = {
  __typename?: 'ContactCategoryByIdResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type ContactCategoryResposne = {
  __typename?: 'ContactCategoryResposne';
  data: Array<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  _id: Scalars['String'];
  address: Scalars['String'];
  admissionConfirmed: Scalars['Boolean'];
  assignedUser: User;
  branch?: Maybe<Branch>;
  cancleReasone: Scalars['String'];
  contact1: Scalars['String'];
  contact2: Scalars['String'];
  contactCategory?: Maybe<ContactCategory>;
  feeDeliverd: Scalars['String'];
  feePaid: Scalars['String'];
  followUp?: Maybe<Array<FollowUp>>;
  isActive: Scalars['Boolean'];
  isWalkIn: Scalars['Boolean'];
  name: Scalars['String'];
  preSchool: Scalars['String'];
  preTution: Scalars['String'];
  refrence: Scalars['String'];
  standard?: Maybe<Standard>;
};

export type ContactResposne = {
  __typename?: 'ContactResposne';
  data: Array<Contact>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type Conversation = {
  __typename?: 'Conversation';
  _id: Scalars['String'];
  assignedContact: Contact;
  assignedUser: User;
  branch: Branch;
  chat: Array<Chat>;
  isAdmissionConfirmed: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  standard: Standard;
};

export type CreateBranchResponse = {
  __typename?: 'CreateBranchResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type CreateChatResponse = {
  __typename?: 'CreateChatResponse';
  data: Scalars['String'];
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type CreateContactCategoryResponse = {
  __typename?: 'CreateContactCategoryResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type CreateContactResponse = {
  __typename?: 'CreateContactResponse';
  data?: Maybe<Contact>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type CreateStandardResponse = {
  __typename?: 'CreateStandardResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  data: Scalars['String'];
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type FollowUp = {
  __typename?: 'FollowUp';
  _id: Scalars['String'];
  contactPerson: ContactPerson;
  followUpDate: Scalars['String'];
  followUpImage: Scalars['String'];
  isActive: Scalars['Boolean'];
  message: Scalars['String'];
  optionType: Scalars['String'];
  responseType: Scalars['String'];
};

export type FollowUpInput = {
  contactPersonId: Scalars['String'];
  followUpDate: Scalars['String'];
  followUpImage: Scalars['String'];
  message: Scalars['String'];
  optionType: Scalars['String'];
  responseType: Scalars['String'];
};

export type GetById = {
  _id: Scalars['String'];
};

export type GetChatResponse = {
  __typename?: 'GetChatResponse';
  data?: Maybe<Conversation>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type GetContactByIdResponse = {
  __typename?: 'GetContactByIdResponse';
  data: ContactPerson;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type GetContactPersonInput = {
  address: Scalars['String'];
  admissionConfirmed: Scalars['Boolean'];
  assignedUser: Scalars['String'];
  branch: Scalars['String'];
  cancleReasone: Scalars['String'];
  contact1: Scalars['String'];
  contact2: Scalars['String'];
  contactCategory: Scalars['String'];
  feeDeliverd: Scalars['String'];
  feePaid: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  preSchool: Scalars['String'];
  preTution: Scalars['String'];
  refrence: Scalars['String'];
  standard: Scalars['String'];
};

export type GetConversationResponse = {
  __typename?: 'GetConversationResponse';
  data?: Maybe<Array<Contact>>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AddFollowup: StatusResponse;
  addChat: CreateChatResponse;
  adminUpPassword: StatusResponse;
  auth: AuthResponse;
  createBranch: CreateBranchResponse;
  createContact: CreateContactResponse;
  createContactCategory: CreateContactCategoryResponse;
  createContactPerson: StatusResponse;
  createStandard: CreateStandardResponse;
  createUser: CreateUserResponse;
  deleteBranch: StatusResponse;
  deleteContact: StatusResponse;
  deleteContactCategory: ContactCategoryResposne;
  deleteContactPerson: StatusResponse;
  deleteFollowUpById: StatusResponse;
  deleteStandard: StatusResponse;
  deleteUser: CreateUserResponse;
  delteMessage: CreateChatResponse;
  importContactPerson: StatusResponse;
  upCloseConversation: StatusResponse;
  updateBranch: CreateBranchResponse;
  updateContact: CreateContactResponse;
  updateContactCategory: CreateContactCategoryResponse;
  updateContactPerson: StatusResponse;
  updatePassword: StatusResponse;
  updateStandard: CreateStandardResponse;
  updateUser: CreateUserResponse;
};


export type MutationAddFollowupArgs = {
  options: FollowUpInput;
};


export type MutationAddChatArgs = {
  options: ChatInput;
};


export type MutationAdminUpPasswordArgs = {
  options: AdminUpdatePasswrodInput;
};


export type MutationAuthArgs = {
  options: UserInputType;
};


export type MutationCreateBranchArgs = {
  options: CreateBranch;
};


export type MutationCreateContactArgs = {
  options: CreateContact;
};


export type MutationCreateContactCategoryArgs = {
  options: CreateContactCategory;
};


export type MutationCreateContactPersonArgs = {
  options: GetContactPersonInput;
};


export type MutationCreateStandardArgs = {
  options: CreateStandard;
};


export type MutationCreateUserArgs = {
  options: CreateUserInputType;
};


export type MutationDeleteBranchArgs = {
  options: GetById;
};


export type MutationDeleteContactArgs = {
  options: GetById;
};


export type MutationDeleteContactCategoryArgs = {
  options: GetById;
};


export type MutationDeleteContactPersonArgs = {
  options: GetById;
};


export type MutationDeleteFollowUpByIdArgs = {
  options: GetById;
};


export type MutationDeleteStandardArgs = {
  options: GetById;
};


export type MutationDeleteUserArgs = {
  options: DeleteUserInputType;
};


export type MutationDelteMessageArgs = {
  options: GetById;
};


export type MutationImportContactPersonArgs = {
  options: ImportContactPersonInput;
};


export type MutationUpCloseConversationArgs = {
  options: UpChateInput;
};


export type MutationUpdateBranchArgs = {
  options: UpdateBranch;
};


export type MutationUpdateContactArgs = {
  options: UpdateContact;
};


export type MutationUpdateContactCategoryArgs = {
  options: UpdateContactCategory;
};


export type MutationUpdateContactPersonArgs = {
  options: UpdateContactPersonInput;
};


export type MutationUpdatePasswordArgs = {
  options: UpdatePasswrodInput;
};


export type MutationUpdateStandardArgs = {
  options: UpdateStandard;
};


export type MutationUpdateUserArgs = {
  options: UpdateUserInputType;
};

export type ObjectResponseData = {
  __typename?: 'ObjectResponseData';
  isAdmin: Scalars['Boolean'];
  jwt: Scalars['String'];
  userName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAllContactPerson: AllContactResponse;
  getAllUser: AllUserResponse;
  getBranch: StandardBranchResposne;
  getBranchById: BranchByIdResponse;
  getChats: GetChatResponse;
  getContact: ContactResposne;
  getContactById: ContactByIdResponse;
  getContactCategory: ContactCategoryResposne;
  getContactCategoryById: ContactCategoryByIdResponse;
  getContactPersonById: GetContactByIdResponse;
  getConversation: GetConversationResponse;
  getCount: GetCountOutput;
  getFllowUpById: GetFollowUpResponse;
  getStandard: StandardResposne;
  getStandardById: StandardByIdResponse;
  hello: Scalars['String'];
};


export type QueryGetBranchByIdArgs = {
  options: GetById;
};


export type QueryGetChatsArgs = {
  options: GetById;
};


export type QueryGetContactByIdArgs = {
  options: GetById;
};


export type QueryGetContactCategoryByIdArgs = {
  options: GetById;
};


export type QueryGetContactPersonByIdArgs = {
  options: GetById;
};


export type QueryGetFllowUpByIdArgs = {
  options: GetById;
};


export type QueryGetStandardByIdArgs = {
  options: GetById;
};

export type Standard = {
  __typename?: 'Standard';
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type StandardBranchResposne = {
  __typename?: 'StandardBranchResposne';
  data: Array<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type StandardByIdResponse = {
  __typename?: 'StandardByIdResponse';
  data?: Maybe<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type StandardResposne = {
  __typename?: 'StandardResposne';
  data: Array<Standard>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  data: Scalars['String'];
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  email: Scalars['String'];
  hash: Scalars['String'];
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type AdminUpdatePasswrodInput = {
  newPassword: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateBranch = {
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateContact = {
  assignedEmployee: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateContactCategory = {
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateStandard = {
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateUserInputType = {
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
  password: Scalars['String'];
  userName: Scalars['String'];
};

export type DeleteUserInputType = {
  userId: Scalars['String'];
};

export type GetCountOutput = {
  __typename?: 'getCountOutput';
  data: GetCountSub;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type GetCountSub = {
  __typename?: 'getCountSub';
  admissionCount: Scalars['Float'];
  admissionFailed: Scalars['Float'];
  totalInquiry: Scalars['Float'];
  walkInInquiry: Scalars['Float'];
  workingInquiery: Scalars['Float'];
};

export type GetFollowUpResponse = {
  __typename?: 'getFollowUpResponse';
  data: Array<FollowUp>;
  isSuccess: Scalars['Boolean'];
  msg: Scalars['String'];
};

export type ImportContactPersonInput = {
  address: Scalars['String'];
  contact1: Scalars['String'];
  contact2: Scalars['String'];
  contactCategory: Scalars['String'];
  empName: Scalars['String'];
  nameOfStudent: Scalars['String'];
  prevSchool: Scalars['String'];
  prevTution: Scalars['String'];
};

export type UpChateInput = {
  branchId: Scalars['String'];
  contactId: Scalars['String'];
  conversationId: Scalars['String'];
  isConfirmed: Scalars['Boolean'];
  standardId: Scalars['String'];
};

export type UpdateBranch = {
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateContact = {
  _id: Scalars['String'];
  assignedEmployee: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UpdateContactCategory = {
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateContactPersonInput = {
  _id: Scalars['String'];
  address: Scalars['String'];
  admissionConfirmed: Scalars['Boolean'];
  assignedUser: Scalars['String'];
  branch: Scalars['String'];
  cancleReasone: Scalars['String'];
  contact1: Scalars['String'];
  contact2: Scalars['String'];
  contactCategory: Scalars['String'];
  feeDeliverd: Scalars['String'];
  feePaid: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  preSchool: Scalars['String'];
  preTution: Scalars['String'];
  refrence: Scalars['String'];
  standard: Scalars['String'];
};

export type UpdatePasswrodInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UpdateStandard = {
  _id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateUserInputType = {
  _id: Scalars['String'];
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
  password: Scalars['String'];
  userName: Scalars['String'];
};

export type UserInputType = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AuthMutationVariables = Exact<{
  options: UserInputType;
}>;


export type AuthMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthResponse', isSuccess: boolean, msg: string, data: { __typename?: 'ObjectResponseData', userName: string, jwt: string, isAdmin: boolean } } };

export type CreateStandardMutationVariables = Exact<{
  options: CreateStandard;
}>;


export type CreateStandardMutation = { __typename?: 'Mutation', createStandard: { __typename?: 'CreateStandardResponse', isSuccess: boolean, msg: string } };

export type DeleteStandardMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteStandardMutation = { __typename?: 'Mutation', deleteStandard: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type UpdateStandardMutationVariables = Exact<{
  options: UpdateStandard;
}>;


export type UpdateStandardMutation = { __typename?: 'Mutation', updateStandard: { __typename?: 'CreateStandardResponse', isSuccess: boolean, msg: string } };

export type CreateContactMutationVariables = Exact<{
  options: CreateContact;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'CreateContactResponse', isSuccess: boolean, msg: string } };

export type DeleteContactMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type UpdateContactMutationVariables = Exact<{
  options: UpdateContact;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'CreateContactResponse', isSuccess: boolean, msg: string } };

export type CreateUserMutationVariables = Exact<{
  options: CreateUserInputType;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateUserResponse', isSuccess: boolean, msg: string } };

export type UpdateUserMutationVariables = Exact<{
  options: UpdateUserInputType;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'CreateUserResponse', isSuccess: boolean, msg: string, data: string } };

export type DeleteUserMutationVariables = Exact<{
  options: DeleteUserInputType;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'CreateUserResponse', isSuccess: boolean, msg: string, data: string } };

export type AddChatMutationVariables = Exact<{
  options: ChatInput;
}>;


export type AddChatMutation = { __typename?: 'Mutation', addChat: { __typename?: 'CreateChatResponse', isSuccess: boolean, msg: string } };

export type CreateBranchMutationVariables = Exact<{
  options: CreateBranch;
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'CreateBranchResponse', isSuccess: boolean, msg: string } };

export type UpdateBranchMutationVariables = Exact<{
  options: UpdateBranch;
}>;


export type UpdateBranchMutation = { __typename?: 'Mutation', updateBranch: { __typename?: 'CreateBranchResponse', isSuccess: boolean, msg: string } };

export type DeleteBranchMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteBranchMutation = { __typename?: 'Mutation', deleteBranch: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type UpCloseConversationMutationVariables = Exact<{
  options: UpChateInput;
}>;


export type UpCloseConversationMutation = { __typename?: 'Mutation', upCloseConversation: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type CreateContactPersonMutationVariables = Exact<{
  options: GetContactPersonInput;
}>;


export type CreateContactPersonMutation = { __typename?: 'Mutation', createContactPerson: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type UpdateContactPersonMutationVariables = Exact<{
  options: UpdateContactPersonInput;
}>;


export type UpdateContactPersonMutation = { __typename?: 'Mutation', updateContactPerson: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type DeleteContactPersonMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteContactPersonMutation = { __typename?: 'Mutation', deleteContactPerson: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type AddFollowupMutationVariables = Exact<{
  options: FollowUpInput;
}>;


export type AddFollowupMutation = { __typename?: 'Mutation', AddFollowup: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type DeleteFollowUpByIdMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteFollowUpByIdMutation = { __typename?: 'Mutation', deleteFollowUpById: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type ImportContactPersonMutationVariables = Exact<{
  options: ImportContactPersonInput;
}>;


export type ImportContactPersonMutation = { __typename?: 'Mutation', importContactPerson: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type UpdatePasswordMutationVariables = Exact<{
  options: UpdatePasswrodInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type AdminUpPasswordMutationVariables = Exact<{
  options: AdminUpdatePasswrodInput;
}>;


export type AdminUpPasswordMutation = { __typename?: 'Mutation', adminUpPassword: { __typename?: 'StatusResponse', isSuccess: boolean, msg: string } };

export type CreateContactCategoryMutationVariables = Exact<{
  options: CreateContactCategory;
}>;


export type CreateContactCategoryMutation = { __typename?: 'Mutation', createContactCategory: { __typename?: 'CreateContactCategoryResponse', isSuccess: boolean, msg: string, data?: { __typename?: 'Standard', _id: string, name: string, isActive: boolean } | null } };

export type UpdateContactCategoryMutationVariables = Exact<{
  options: UpdateContactCategory;
}>;


export type UpdateContactCategoryMutation = { __typename?: 'Mutation', updateContactCategory: { __typename?: 'CreateContactCategoryResponse', isSuccess: boolean, msg: string, data?: { __typename?: 'Standard', _id: string, name: string, isActive: boolean } | null } };

export type DeleteContactCategoryMutationVariables = Exact<{
  options: GetById;
}>;


export type DeleteContactCategoryMutation = { __typename?: 'Mutation', deleteContactCategory: { __typename?: 'ContactCategoryResposne', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'Standard', _id: string, name: string, isActive: boolean }> } };

export type GetStandardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStandardQuery = { __typename?: 'Query', getStandard: { __typename?: 'StandardResposne', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'Standard', _id: string, isActive: boolean, name: string }> } };

export type GetContactQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactQuery = { __typename?: 'Query', getContact: { __typename?: 'ContactResposne', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'Contact', _id: string, name: string, isClosed: boolean, isAdmissionConfirmed: boolean, isCompleted: boolean, phoneNumber: string, isActive: boolean, assignedEmployee: { __typename?: 'User', _id: string, email: string, userName: string, hash: string, isAdmin: boolean, isActive: boolean } }> } };

export type GetAllUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserQuery = { __typename?: 'Query', getAllUser: { __typename?: 'AllUserResponse', isSuccess: boolean, msg: string, data?: Array<{ __typename?: 'User', _id: string, email: string, hash: string, isAdmin: boolean, userName: string, isActive: boolean }> | null } };

export type GetConversationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConversationQuery = { __typename?: 'Query', getConversation: { __typename?: 'GetConversationResponse', isSuccess: boolean, msg: string, data?: Array<{ __typename?: 'Contact', _id: string, name: string, isClosed: boolean, isAdmissionConfirmed: boolean, isCompleted: boolean, phoneNumber: string, isActive: boolean, assignedEmployee: { __typename?: 'User', _id: string, email: string, userName: string, hash: string, isAdmin: boolean, isActive: boolean } }> | null } };

export type GetChatsQueryVariables = Exact<{
  options: GetById;
}>;


export type GetChatsQuery = { __typename?: 'Query', getChats: { __typename?: 'GetChatResponse', isSuccess: boolean, msg: string, data?: { __typename?: 'Conversation', _id: string, isClosed: boolean, assignedUser: { __typename?: 'User', _id: string, email: string, userName: string, hash: string, isAdmin: boolean, isActive: boolean }, assignedContact: { __typename?: 'Contact', _id: string, name: string, isCompleted: boolean, phoneNumber: string, isActive: boolean }, chat: Array<{ __typename?: 'Chat', _id: string, message: string, createdAt: any }> } | null } };

export type GetBranchQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBranchQuery = { __typename?: 'Query', getBranch: { __typename?: 'StandardBranchResposne', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'Standard', _id: string, name: string, isActive: boolean }> } };

export type GetAllContactPersonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContactPersonQuery = { __typename?: 'Query', getAllContactPerson: { __typename?: 'AllContactResponse', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'ContactPerson', _id: string, name: string, address: string, preSchool: string, preTution: string, contact1: string, contact2: string, refrence: string, admissionConfirmed: boolean, feeDeliverd: string, feePaid: string, cancleReasone: string, isWalkIn: boolean, isActive: boolean, contactCategory?: { __typename?: 'ContactCategory', _id: string, name: string, isActive: boolean } | null, assignedUser: { __typename?: 'User', _id: string, email: string, userName: string, hash: string, isAdmin: boolean, isActive: boolean }, standard?: { __typename?: 'Standard', _id: string, name: string, isActive: boolean } | null, followUp?: Array<{ __typename?: 'FollowUp', _id: string, message: string, isActive: boolean }> | null }> } };

export type GetContactPersonByIdQueryVariables = Exact<{
  options: GetById;
}>;


export type GetContactPersonByIdQuery = { __typename?: 'Query', getContactPersonById: { __typename?: 'GetContactByIdResponse', isSuccess: boolean, msg: string, data: { __typename?: 'ContactPerson', _id: string, name: string, address: string, preSchool: string, preTution: string, contact1: string, contact2: string, refrence: string, admissionConfirmed: boolean, feeDeliverd: string, feePaid: string, cancleReasone: string, isActive: boolean, assignedUser: { __typename?: 'User', _id: string, email: string, userName: string, hash: string, isAdmin: boolean, isActive: boolean }, contactCategory?: { __typename?: 'ContactCategory', _id: string, name: string, isActive: boolean } | null, standard?: { __typename?: 'Standard', _id: string, name: string, isActive: boolean } | null, branch?: { __typename?: 'Branch', _id: string, name: string, isActive: boolean } | null, followUp?: Array<{ __typename?: 'FollowUp', _id: string, message: string, isActive: boolean }> | null } } };

export type GetFllowUpByIdQueryVariables = Exact<{
  options: GetById;
}>;


export type GetFllowUpByIdQuery = { __typename?: 'Query', getFllowUpById: { __typename?: 'getFollowUpResponse', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'FollowUp', followUpImage: string, isActive: boolean, message: string, _id: string, followUpDate: string, responseType: string, optionType: string }> } };

export type GetCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountQuery = { __typename?: 'Query', getCount: { __typename?: 'getCountOutput', isSuccess: boolean, msg: string, data: { __typename?: 'getCountSub', totalInquiry: number, admissionCount: number, workingInquiery: number, admissionFailed: number, walkInInquiry: number } } };

export type GetContactCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactCategoryQuery = { __typename?: 'Query', getContactCategory: { __typename?: 'ContactCategoryResposne', isSuccess: boolean, msg: string, data: Array<{ __typename?: 'Standard', _id: string, name: string, isActive: boolean }> } };

export type GetContactCategoryByIdQueryVariables = Exact<{
  options: GetById;
}>;


export type GetContactCategoryByIdQuery = { __typename?: 'Query', getContactCategoryById: { __typename?: 'ContactCategoryByIdResponse', isSuccess: boolean, msg: string, data?: { __typename?: 'Standard', _id: string, name: string, isActive: boolean } | null } };


export const AuthDocument = gql`
    mutation Auth($options: userInputType!) {
  auth(options: $options) {
    data {
      userName
      jwt
      isAdmin
    }
    isSuccess
    msg
  }
}
    `;
export type AuthMutationFn = Apollo.MutationFunction<AuthMutation, AuthMutationVariables>;

/**
 * __useAuthMutation__
 *
 * To run a mutation, you first call `useAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authMutation, { data, loading, error }] = useAuthMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAuthMutation(baseOptions?: Apollo.MutationHookOptions<AuthMutation, AuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthMutation, AuthMutationVariables>(AuthDocument, options);
      }
export type AuthMutationHookResult = ReturnType<typeof useAuthMutation>;
export type AuthMutationResult = Apollo.MutationResult<AuthMutation>;
export type AuthMutationOptions = Apollo.BaseMutationOptions<AuthMutation, AuthMutationVariables>;
export const CreateStandardDocument = gql`
    mutation CreateStandard($options: createStandard!) {
  createStandard(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type CreateStandardMutationFn = Apollo.MutationFunction<CreateStandardMutation, CreateStandardMutationVariables>;

/**
 * __useCreateStandardMutation__
 *
 * To run a mutation, you first call `useCreateStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandardMutation, { data, loading, error }] = useCreateStandardMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateStandardMutation(baseOptions?: Apollo.MutationHookOptions<CreateStandardMutation, CreateStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStandardMutation, CreateStandardMutationVariables>(CreateStandardDocument, options);
      }
export type CreateStandardMutationHookResult = ReturnType<typeof useCreateStandardMutation>;
export type CreateStandardMutationResult = Apollo.MutationResult<CreateStandardMutation>;
export type CreateStandardMutationOptions = Apollo.BaseMutationOptions<CreateStandardMutation, CreateStandardMutationVariables>;
export const DeleteStandardDocument = gql`
    mutation DeleteStandard($options: GetById!) {
  deleteStandard(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type DeleteStandardMutationFn = Apollo.MutationFunction<DeleteStandardMutation, DeleteStandardMutationVariables>;

/**
 * __useDeleteStandardMutation__
 *
 * To run a mutation, you first call `useDeleteStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardMutation, { data, loading, error }] = useDeleteStandardMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteStandardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStandardMutation, DeleteStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStandardMutation, DeleteStandardMutationVariables>(DeleteStandardDocument, options);
      }
export type DeleteStandardMutationHookResult = ReturnType<typeof useDeleteStandardMutation>;
export type DeleteStandardMutationResult = Apollo.MutationResult<DeleteStandardMutation>;
export type DeleteStandardMutationOptions = Apollo.BaseMutationOptions<DeleteStandardMutation, DeleteStandardMutationVariables>;
export const UpdateStandardDocument = gql`
    mutation UpdateStandard($options: updateStandard!) {
  updateStandard(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpdateStandardMutationFn = Apollo.MutationFunction<UpdateStandardMutation, UpdateStandardMutationVariables>;

/**
 * __useUpdateStandardMutation__
 *
 * To run a mutation, you first call `useUpdateStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandardMutation, { data, loading, error }] = useUpdateStandardMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateStandardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStandardMutation, UpdateStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStandardMutation, UpdateStandardMutationVariables>(UpdateStandardDocument, options);
      }
export type UpdateStandardMutationHookResult = ReturnType<typeof useUpdateStandardMutation>;
export type UpdateStandardMutationResult = Apollo.MutationResult<UpdateStandardMutation>;
export type UpdateStandardMutationOptions = Apollo.BaseMutationOptions<UpdateStandardMutation, UpdateStandardMutationVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($options: createContact!) {
  createContact(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($options: GetById!) {
  deleteContact(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($options: updateContact!) {
  updateContact(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($options: createUserInputType!) {
  createUser(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($options: updateUserInputType!) {
  updateUser(options: $options) {
    isSuccess
    msg
    data
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($options: deleteUserInputType!) {
  deleteUser(options: $options) {
    isSuccess
    msg
    data
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AddChatDocument = gql`
    mutation AddChat($options: ChatInput!) {
  addChat(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type AddChatMutationFn = Apollo.MutationFunction<AddChatMutation, AddChatMutationVariables>;

/**
 * __useAddChatMutation__
 *
 * To run a mutation, you first call `useAddChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatMutation, { data, loading, error }] = useAddChatMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAddChatMutation(baseOptions?: Apollo.MutationHookOptions<AddChatMutation, AddChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChatMutation, AddChatMutationVariables>(AddChatDocument, options);
      }
export type AddChatMutationHookResult = ReturnType<typeof useAddChatMutation>;
export type AddChatMutationResult = Apollo.MutationResult<AddChatMutation>;
export type AddChatMutationOptions = Apollo.BaseMutationOptions<AddChatMutation, AddChatMutationVariables>;
export const CreateBranchDocument = gql`
    mutation CreateBranch($options: createBranch!) {
  createBranch(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;
export const UpdateBranchDocument = gql`
    mutation UpdateBranch($options: updateBranch!) {
  updateBranch(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpdateBranchMutationFn = Apollo.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateBranchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, options);
      }
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = Apollo.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = Apollo.BaseMutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>;
export const DeleteBranchDocument = gql`
    mutation DeleteBranch($options: GetById!) {
  deleteBranch(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type DeleteBranchMutationFn = Apollo.MutationFunction<DeleteBranchMutation, DeleteBranchMutationVariables>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBranchMutation, DeleteBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(DeleteBranchDocument, options);
      }
export type DeleteBranchMutationHookResult = ReturnType<typeof useDeleteBranchMutation>;
export type DeleteBranchMutationResult = Apollo.MutationResult<DeleteBranchMutation>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const UpCloseConversationDocument = gql`
    mutation UpCloseConversation($options: upChateInput!) {
  upCloseConversation(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpCloseConversationMutationFn = Apollo.MutationFunction<UpCloseConversationMutation, UpCloseConversationMutationVariables>;

/**
 * __useUpCloseConversationMutation__
 *
 * To run a mutation, you first call `useUpCloseConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpCloseConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upCloseConversationMutation, { data, loading, error }] = useUpCloseConversationMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpCloseConversationMutation(baseOptions?: Apollo.MutationHookOptions<UpCloseConversationMutation, UpCloseConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpCloseConversationMutation, UpCloseConversationMutationVariables>(UpCloseConversationDocument, options);
      }
export type UpCloseConversationMutationHookResult = ReturnType<typeof useUpCloseConversationMutation>;
export type UpCloseConversationMutationResult = Apollo.MutationResult<UpCloseConversationMutation>;
export type UpCloseConversationMutationOptions = Apollo.BaseMutationOptions<UpCloseConversationMutation, UpCloseConversationMutationVariables>;
export const CreateContactPersonDocument = gql`
    mutation CreateContactPerson($options: GetContactPersonInput!) {
  createContactPerson(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type CreateContactPersonMutationFn = Apollo.MutationFunction<CreateContactPersonMutation, CreateContactPersonMutationVariables>;

/**
 * __useCreateContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactPersonMutation, { data, loading, error }] = useCreateContactPersonMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactPersonMutation, CreateContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactPersonMutation, CreateContactPersonMutationVariables>(CreateContactPersonDocument, options);
      }
export type CreateContactPersonMutationHookResult = ReturnType<typeof useCreateContactPersonMutation>;
export type CreateContactPersonMutationResult = Apollo.MutationResult<CreateContactPersonMutation>;
export type CreateContactPersonMutationOptions = Apollo.BaseMutationOptions<CreateContactPersonMutation, CreateContactPersonMutationVariables>;
export const UpdateContactPersonDocument = gql`
    mutation UpdateContactPerson($options: updateContactPersonInput!) {
  updateContactPerson(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpdateContactPersonMutationFn = Apollo.MutationFunction<UpdateContactPersonMutation, UpdateContactPersonMutationVariables>;

/**
 * __useUpdateContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactPersonMutation, { data, loading, error }] = useUpdateContactPersonMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactPersonMutation, UpdateContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactPersonMutation, UpdateContactPersonMutationVariables>(UpdateContactPersonDocument, options);
      }
export type UpdateContactPersonMutationHookResult = ReturnType<typeof useUpdateContactPersonMutation>;
export type UpdateContactPersonMutationResult = Apollo.MutationResult<UpdateContactPersonMutation>;
export type UpdateContactPersonMutationOptions = Apollo.BaseMutationOptions<UpdateContactPersonMutation, UpdateContactPersonMutationVariables>;
export const DeleteContactPersonDocument = gql`
    mutation DeleteContactPerson($options: GetById!) {
  deleteContactPerson(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type DeleteContactPersonMutationFn = Apollo.MutationFunction<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>;

/**
 * __useDeleteContactPersonMutation__
 *
 * To run a mutation, you first call `useDeleteContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactPersonMutation, { data, loading, error }] = useDeleteContactPersonMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>(DeleteContactPersonDocument, options);
      }
export type DeleteContactPersonMutationHookResult = ReturnType<typeof useDeleteContactPersonMutation>;
export type DeleteContactPersonMutationResult = Apollo.MutationResult<DeleteContactPersonMutation>;
export type DeleteContactPersonMutationOptions = Apollo.BaseMutationOptions<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>;
export const AddFollowupDocument = gql`
    mutation AddFollowup($options: FollowUpInput!) {
  AddFollowup(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type AddFollowupMutationFn = Apollo.MutationFunction<AddFollowupMutation, AddFollowupMutationVariables>;

/**
 * __useAddFollowupMutation__
 *
 * To run a mutation, you first call `useAddFollowupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFollowupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFollowupMutation, { data, loading, error }] = useAddFollowupMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAddFollowupMutation(baseOptions?: Apollo.MutationHookOptions<AddFollowupMutation, AddFollowupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFollowupMutation, AddFollowupMutationVariables>(AddFollowupDocument, options);
      }
export type AddFollowupMutationHookResult = ReturnType<typeof useAddFollowupMutation>;
export type AddFollowupMutationResult = Apollo.MutationResult<AddFollowupMutation>;
export type AddFollowupMutationOptions = Apollo.BaseMutationOptions<AddFollowupMutation, AddFollowupMutationVariables>;
export const DeleteFollowUpByIdDocument = gql`
    mutation DeleteFollowUpById($options: GetById!) {
  deleteFollowUpById(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type DeleteFollowUpByIdMutationFn = Apollo.MutationFunction<DeleteFollowUpByIdMutation, DeleteFollowUpByIdMutationVariables>;

/**
 * __useDeleteFollowUpByIdMutation__
 *
 * To run a mutation, you first call `useDeleteFollowUpByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFollowUpByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFollowUpByIdMutation, { data, loading, error }] = useDeleteFollowUpByIdMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteFollowUpByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFollowUpByIdMutation, DeleteFollowUpByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFollowUpByIdMutation, DeleteFollowUpByIdMutationVariables>(DeleteFollowUpByIdDocument, options);
      }
export type DeleteFollowUpByIdMutationHookResult = ReturnType<typeof useDeleteFollowUpByIdMutation>;
export type DeleteFollowUpByIdMutationResult = Apollo.MutationResult<DeleteFollowUpByIdMutation>;
export type DeleteFollowUpByIdMutationOptions = Apollo.BaseMutationOptions<DeleteFollowUpByIdMutation, DeleteFollowUpByIdMutationVariables>;
export const ImportContactPersonDocument = gql`
    mutation ImportContactPerson($options: importContactPersonInput!) {
  importContactPerson(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type ImportContactPersonMutationFn = Apollo.MutationFunction<ImportContactPersonMutation, ImportContactPersonMutationVariables>;

/**
 * __useImportContactPersonMutation__
 *
 * To run a mutation, you first call `useImportContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importContactPersonMutation, { data, loading, error }] = useImportContactPersonMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useImportContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<ImportContactPersonMutation, ImportContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportContactPersonMutation, ImportContactPersonMutationVariables>(ImportContactPersonDocument, options);
      }
export type ImportContactPersonMutationHookResult = ReturnType<typeof useImportContactPersonMutation>;
export type ImportContactPersonMutationResult = Apollo.MutationResult<ImportContactPersonMutation>;
export type ImportContactPersonMutationOptions = Apollo.BaseMutationOptions<ImportContactPersonMutation, ImportContactPersonMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($options: updatePasswrodInput!) {
  updatePassword(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const AdminUpPasswordDocument = gql`
    mutation AdminUpPassword($options: adminUpdatePasswrodInput!) {
  adminUpPassword(options: $options) {
    isSuccess
    msg
  }
}
    `;
export type AdminUpPasswordMutationFn = Apollo.MutationFunction<AdminUpPasswordMutation, AdminUpPasswordMutationVariables>;

/**
 * __useAdminUpPasswordMutation__
 *
 * To run a mutation, you first call `useAdminUpPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpPasswordMutation, { data, loading, error }] = useAdminUpPasswordMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAdminUpPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpPasswordMutation, AdminUpPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpPasswordMutation, AdminUpPasswordMutationVariables>(AdminUpPasswordDocument, options);
      }
export type AdminUpPasswordMutationHookResult = ReturnType<typeof useAdminUpPasswordMutation>;
export type AdminUpPasswordMutationResult = Apollo.MutationResult<AdminUpPasswordMutation>;
export type AdminUpPasswordMutationOptions = Apollo.BaseMutationOptions<AdminUpPasswordMutation, AdminUpPasswordMutationVariables>;
export const CreateContactCategoryDocument = gql`
    mutation CreateContactCategory($options: createContactCategory!) {
  createContactCategory(options: $options) {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;
export type CreateContactCategoryMutationFn = Apollo.MutationFunction<CreateContactCategoryMutation, CreateContactCategoryMutationVariables>;

/**
 * __useCreateContactCategoryMutation__
 *
 * To run a mutation, you first call `useCreateContactCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactCategoryMutation, { data, loading, error }] = useCreateContactCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateContactCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactCategoryMutation, CreateContactCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactCategoryMutation, CreateContactCategoryMutationVariables>(CreateContactCategoryDocument, options);
      }
export type CreateContactCategoryMutationHookResult = ReturnType<typeof useCreateContactCategoryMutation>;
export type CreateContactCategoryMutationResult = Apollo.MutationResult<CreateContactCategoryMutation>;
export type CreateContactCategoryMutationOptions = Apollo.BaseMutationOptions<CreateContactCategoryMutation, CreateContactCategoryMutationVariables>;
export const UpdateContactCategoryDocument = gql`
    mutation UpdateContactCategory($options: updateContactCategory!) {
  updateContactCategory(options: $options) {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;
export type UpdateContactCategoryMutationFn = Apollo.MutationFunction<UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables>;

/**
 * __useUpdateContactCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateContactCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactCategoryMutation, { data, loading, error }] = useUpdateContactCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateContactCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables>(UpdateContactCategoryDocument, options);
      }
export type UpdateContactCategoryMutationHookResult = ReturnType<typeof useUpdateContactCategoryMutation>;
export type UpdateContactCategoryMutationResult = Apollo.MutationResult<UpdateContactCategoryMutation>;
export type UpdateContactCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables>;
export const DeleteContactCategoryDocument = gql`
    mutation DeleteContactCategory($options: GetById!) {
  deleteContactCategory(options: $options) {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;
export type DeleteContactCategoryMutationFn = Apollo.MutationFunction<DeleteContactCategoryMutation, DeleteContactCategoryMutationVariables>;

/**
 * __useDeleteContactCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteContactCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactCategoryMutation, { data, loading, error }] = useDeleteContactCategoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteContactCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactCategoryMutation, DeleteContactCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactCategoryMutation, DeleteContactCategoryMutationVariables>(DeleteContactCategoryDocument, options);
      }
export type DeleteContactCategoryMutationHookResult = ReturnType<typeof useDeleteContactCategoryMutation>;
export type DeleteContactCategoryMutationResult = Apollo.MutationResult<DeleteContactCategoryMutation>;
export type DeleteContactCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteContactCategoryMutation, DeleteContactCategoryMutationVariables>;
export const GetStandardDocument = gql`
    query GetStandard {
  getStandard {
    data {
      _id
      isActive
      name
    }
    isSuccess
    msg
  }
}
    `;

/**
 * __useGetStandardQuery__
 *
 * To run a query within a React component, call `useGetStandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStandardQuery(baseOptions?: Apollo.QueryHookOptions<GetStandardQuery, GetStandardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardQuery, GetStandardQueryVariables>(GetStandardDocument, options);
      }
export function useGetStandardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardQuery, GetStandardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardQuery, GetStandardQueryVariables>(GetStandardDocument, options);
        }
export type GetStandardQueryHookResult = ReturnType<typeof useGetStandardQuery>;
export type GetStandardLazyQueryHookResult = ReturnType<typeof useGetStandardLazyQuery>;
export type GetStandardQueryResult = Apollo.QueryResult<GetStandardQuery, GetStandardQueryVariables>;
export const GetContactDocument = gql`
    query GetContact {
  getContact {
    isSuccess
    msg
    data {
      _id
      name
      isClosed
      isAdmissionConfirmed
      isCompleted
      phoneNumber
      assignedEmployee {
        _id
        email
        userName
        hash
        isAdmin
        isActive
      }
      isActive
    }
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactQuery(baseOptions?: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetAllUserDocument = gql`
    query GetAllUser {
  getAllUser {
    data {
      _id
      email
      hash
      isAdmin
      userName
      isActive
    }
    isSuccess
    msg
  }
}
    `;

/**
 * __useGetAllUserQuery__
 *
 * To run a query within a React component, call `useGetAllUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUserQuery, GetAllUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUserQuery, GetAllUserQueryVariables>(GetAllUserDocument, options);
      }
export function useGetAllUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUserQuery, GetAllUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUserQuery, GetAllUserQueryVariables>(GetAllUserDocument, options);
        }
export type GetAllUserQueryHookResult = ReturnType<typeof useGetAllUserQuery>;
export type GetAllUserLazyQueryHookResult = ReturnType<typeof useGetAllUserLazyQuery>;
export type GetAllUserQueryResult = Apollo.QueryResult<GetAllUserQuery, GetAllUserQueryVariables>;
export const GetConversationDocument = gql`
    query GetConversation {
  getConversation {
    isSuccess
    msg
    data {
      _id
      name
      isClosed
      isAdmissionConfirmed
      isCompleted
      phoneNumber
      assignedEmployee {
        _id
        email
        userName
        hash
        isAdmin
        isActive
      }
      isActive
    }
  }
}
    `;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConversationQuery(baseOptions?: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
      }
export function useGetConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
        }
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetChatsDocument = gql`
    query GetChats($options: GetById!) {
  getChats(options: $options) {
    data {
      _id
      assignedUser {
        _id
        email
        userName
        hash
        isAdmin
        isActive
      }
      assignedContact {
        _id
        name
        isCompleted
        phoneNumber
        isActive
      }
      chat {
        _id
        message
        createdAt
      }
      isClosed
    }
    isSuccess
    msg
  }
}
    `;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
      }
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
        }
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const GetBranchDocument = gql`
    query GetBranch {
  getBranch {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;

/**
 * __useGetBranchQuery__
 *
 * To run a query within a React component, call `useGetBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBranchQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
      }
export function useGetBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchQuery, GetBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchQuery, GetBranchQueryVariables>(GetBranchDocument, options);
        }
export type GetBranchQueryHookResult = ReturnType<typeof useGetBranchQuery>;
export type GetBranchLazyQueryHookResult = ReturnType<typeof useGetBranchLazyQuery>;
export type GetBranchQueryResult = Apollo.QueryResult<GetBranchQuery, GetBranchQueryVariables>;
export const GetAllContactPersonDocument = gql`
    query GetAllContactPerson {
  getAllContactPerson {
    isSuccess
    msg
    data {
      _id
      name
      address
      preSchool
      preTution
      contact1
      contact2
      refrence
      admissionConfirmed
      feeDeliverd
      feePaid
      cancleReasone
      contactCategory {
        _id
        name
        isActive
      }
      isWalkIn
      assignedUser {
        _id
        email
        userName
        hash
        isAdmin
        isActive
      }
      standard {
        _id
        name
        isActive
      }
      isActive
      followUp {
        _id
        message
        isActive
      }
    }
  }
}
    `;

/**
 * __useGetAllContactPersonQuery__
 *
 * To run a query within a React component, call `useGetAllContactPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContactPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContactPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContactPersonQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContactPersonQuery, GetAllContactPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContactPersonQuery, GetAllContactPersonQueryVariables>(GetAllContactPersonDocument, options);
      }
export function useGetAllContactPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContactPersonQuery, GetAllContactPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContactPersonQuery, GetAllContactPersonQueryVariables>(GetAllContactPersonDocument, options);
        }
export type GetAllContactPersonQueryHookResult = ReturnType<typeof useGetAllContactPersonQuery>;
export type GetAllContactPersonLazyQueryHookResult = ReturnType<typeof useGetAllContactPersonLazyQuery>;
export type GetAllContactPersonQueryResult = Apollo.QueryResult<GetAllContactPersonQuery, GetAllContactPersonQueryVariables>;
export const GetContactPersonByIdDocument = gql`
    query GetContactPersonById($options: GetById!) {
  getContactPersonById(options: $options) {
    isSuccess
    msg
    data {
      _id
      name
      address
      preSchool
      preTution
      contact1
      contact2
      refrence
      admissionConfirmed
      feeDeliverd
      feePaid
      cancleReasone
      assignedUser {
        _id
        email
        userName
        hash
        isAdmin
        isActive
      }
      contactCategory {
        _id
        name
        isActive
      }
      standard {
        _id
        name
        isActive
      }
      branch {
        _id
        name
        isActive
      }
      followUp {
        _id
        message
        isActive
      }
      isActive
    }
  }
}
    `;

/**
 * __useGetContactPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetContactPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactPersonByIdQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetContactPersonByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactPersonByIdQuery, GetContactPersonByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactPersonByIdQuery, GetContactPersonByIdQueryVariables>(GetContactPersonByIdDocument, options);
      }
export function useGetContactPersonByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactPersonByIdQuery, GetContactPersonByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactPersonByIdQuery, GetContactPersonByIdQueryVariables>(GetContactPersonByIdDocument, options);
        }
export type GetContactPersonByIdQueryHookResult = ReturnType<typeof useGetContactPersonByIdQuery>;
export type GetContactPersonByIdLazyQueryHookResult = ReturnType<typeof useGetContactPersonByIdLazyQuery>;
export type GetContactPersonByIdQueryResult = Apollo.QueryResult<GetContactPersonByIdQuery, GetContactPersonByIdQueryVariables>;
export const GetFllowUpByIdDocument = gql`
    query GetFllowUpById($options: GetById!) {
  getFllowUpById(options: $options) {
    isSuccess
    msg
    data {
      followUpImage
      isActive
      message
      _id
      followUpDate
      responseType
      optionType
    }
  }
}
    `;

/**
 * __useGetFllowUpByIdQuery__
 *
 * To run a query within a React component, call `useGetFllowUpByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFllowUpByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFllowUpByIdQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetFllowUpByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFllowUpByIdQuery, GetFllowUpByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFllowUpByIdQuery, GetFllowUpByIdQueryVariables>(GetFllowUpByIdDocument, options);
      }
export function useGetFllowUpByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFllowUpByIdQuery, GetFllowUpByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFllowUpByIdQuery, GetFllowUpByIdQueryVariables>(GetFllowUpByIdDocument, options);
        }
export type GetFllowUpByIdQueryHookResult = ReturnType<typeof useGetFllowUpByIdQuery>;
export type GetFllowUpByIdLazyQueryHookResult = ReturnType<typeof useGetFllowUpByIdLazyQuery>;
export type GetFllowUpByIdQueryResult = Apollo.QueryResult<GetFllowUpByIdQuery, GetFllowUpByIdQueryVariables>;
export const GetCountDocument = gql`
    query GetCount {
  getCount {
    isSuccess
    msg
    data {
      totalInquiry
      admissionCount
      workingInquiery
      admissionFailed
      walkInInquiry
    }
  }
}
    `;

/**
 * __useGetCountQuery__
 *
 * To run a query within a React component, call `useGetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountQuery(baseOptions?: Apollo.QueryHookOptions<GetCountQuery, GetCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountQuery, GetCountQueryVariables>(GetCountDocument, options);
      }
export function useGetCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountQuery, GetCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountQuery, GetCountQueryVariables>(GetCountDocument, options);
        }
export type GetCountQueryHookResult = ReturnType<typeof useGetCountQuery>;
export type GetCountLazyQueryHookResult = ReturnType<typeof useGetCountLazyQuery>;
export type GetCountQueryResult = Apollo.QueryResult<GetCountQuery, GetCountQueryVariables>;
export const GetContactCategoryDocument = gql`
    query GetContactCategory {
  getContactCategory {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;

/**
 * __useGetContactCategoryQuery__
 *
 * To run a query within a React component, call `useGetContactCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetContactCategoryQuery, GetContactCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactCategoryQuery, GetContactCategoryQueryVariables>(GetContactCategoryDocument, options);
      }
export function useGetContactCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactCategoryQuery, GetContactCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactCategoryQuery, GetContactCategoryQueryVariables>(GetContactCategoryDocument, options);
        }
export type GetContactCategoryQueryHookResult = ReturnType<typeof useGetContactCategoryQuery>;
export type GetContactCategoryLazyQueryHookResult = ReturnType<typeof useGetContactCategoryLazyQuery>;
export type GetContactCategoryQueryResult = Apollo.QueryResult<GetContactCategoryQuery, GetContactCategoryQueryVariables>;
export const GetContactCategoryByIdDocument = gql`
    query GetContactCategoryById($options: GetById!) {
  getContactCategoryById(options: $options) {
    isSuccess
    msg
    data {
      _id
      name
      isActive
    }
  }
}
    `;

/**
 * __useGetContactCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetContactCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCategoryByIdQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetContactCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactCategoryByIdQuery, GetContactCategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactCategoryByIdQuery, GetContactCategoryByIdQueryVariables>(GetContactCategoryByIdDocument, options);
      }
export function useGetContactCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactCategoryByIdQuery, GetContactCategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactCategoryByIdQuery, GetContactCategoryByIdQueryVariables>(GetContactCategoryByIdDocument, options);
        }
export type GetContactCategoryByIdQueryHookResult = ReturnType<typeof useGetContactCategoryByIdQuery>;
export type GetContactCategoryByIdLazyQueryHookResult = ReturnType<typeof useGetContactCategoryByIdLazyQuery>;
export type GetContactCategoryByIdQueryResult = Apollo.QueryResult<GetContactCategoryByIdQuery, GetContactCategoryByIdQueryVariables>;