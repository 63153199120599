import { css } from "@emotion/css";
import _, { isEqual } from "lodash";
import { FC, memo, useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

export interface IFielUploadResponse {
  success: boolean;
  msg: string;
  data: Data;
}

export interface Data {
  fileName: string;
}

const InfoIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-info-circle"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z" />
      <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
  );
};

const DeleteIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-x-circle"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};

export const CustomFileInput: FC<{
  label: string;
  value: string | number;
  name: string;
  isInvalid: boolean;
  err: string | undefined;
  md?: string;
  lg?: string;
  type?: string;
  placeholder: string;
  setFieldValue: (arg0: string, val: any) => void;
  isImage: boolean;
  isPdf?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
}> = memo(
  ({
    label,
    value,
    name,
    isInvalid,
    err,
    md = 12,
    lg = 12,
    placeholder,
    type = "file",
    setFieldValue,
    isImage,
    isPdf = false,
    isRequired = false,
  }) => {
    const parsedUrl = new URL(
      window.location.origin.includes("inquiry.scpledu.com") === true
        ? `${window.location.origin}/graphql`
        : "http://localhost:5000/graphql"
    );
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
      if (value) {
        setFileName(_.toString(value));
      }
    }, [value]);

    const handleFileChnage = async (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);

      if (e.target.files) console.log(e.target.files[0]);

      if (isImage === true) {
        if (
          e.target.files &&
          e.target.files.length !== 0 &&
          e.target.files[0].type.includes("image")
        ) {
          const fromData = new FormData();
          fromData.append("image", e.target.files[0]);

          await fetch(`${parsedUrl.origin}/upload`, {
            method: "POST",
            body: fromData,
            redirect: "follow",
          })
            .then(async (res) => {
              const data = (await res.json()) as IFielUploadResponse;

              if (data.success) {
                toast.success(data.msg);

                setFileName(data.data.fileName);
                setFieldValue(name, data.data.fileName);
              } else {
                toast.error(data.msg);
              }
            })
            .catch((err) => {
              setFieldValue(name, value);
            });
        } else {
          toast.success("Please select valid file");
        }
      } else if (isPdf === true) {
        if (
          e.target.files &&
          e.target.files.length !== 0 &&
          e.target.files[0].type.includes("pdf")
        ) {
          const fromData = new FormData();
          fromData.append("image", e.target.files[0]);

          await fetch(`${parsedUrl.origin}/upload`, {
            method: "POST",
            body: fromData,
            redirect: "follow",
          })
            .then(async (res) => {
              const data = (await res.json()) as IFielUploadResponse;

              setFieldValue(name, data.data.fileName);
            })
            .catch((err) => {
              setFieldValue(name, value);
            });
        } else {
          toast.error("Please select valid file");
        }
      } else {
        if (e.target.files && e.target.files.length !== 0) {
          const fromData = new FormData();
          fromData.append("image", e.target.files[0]);

          await fetch(`${parsedUrl.origin}/upload`, {
            method: "POST",
            body: fromData,
            redirect: "follow",
          })
            .then(async (res) => {
              const data = (await res.json()) as IFielUploadResponse;

              setFieldValue(name, data.data.fileName);
            })
            .catch((err) => {
              setFieldValue(name, value);
            });
        } else {
          toast.error("Please select valid file");
        }
      }
      setIsLoading(false);
    };

    const handledelete = () => {
      setFieldValue(name, " ");
      console.log("file removed");
    };

    return (
      <Form.Group className="mb-3" controlId="file" as={Col} md={md} lg={lg}>
        <Form.Label style={{ display: "flex", gap: ".5rem" }}>
          {_.capitalize(label)}
          {isRequired === true && (
            <span
              className={css`
                font-size: 1.2rem;
                display: inline !important;
                position: unset !important;
                color: red;
              `}
            >
              *
            </span>
          )}
          {fileName && isImage && (
            <OverlayTrigger
              trigger="hover"
              placement="right"
              overlay={
                <Popover>
                  <Popover.Body>
                    <img
                      className="img-fluid"
                      width="240"
                      src={`${parsedUrl.origin}/upload/${fileName}`}
                      alt="image not found"
                    />
                  </Popover.Body>
                </Popover>
              }
            >
              <span
                className={css`
                  &:hover {
                    cursor: pointer;
                  }
                `}
              >
                <InfoIcon />
              </span>
            </OverlayTrigger>
          )}
          <span
            className={css`
              &:hover {
                cursor: pointer;
              }
            `}
            onClick={handledelete}
          >
            <DeleteIcon />
          </span>
          {fileName && isImage === false && (
            <span
              className={css`
                &:hover {
                  cursor: pointer;
                }
              `}
            >
              <a
                href={`${parsedUrl.origin}/upload/${fileName}`}
                target="_blank"
              >
                <InfoIcon />
              </a>
            </span>
          )}
          {false && <span style={{ color: "red" }}>*</span>}
          {isLoading && (
            <Spinner
              variant="primary"
              animation="border"
              role="status"
              style={{ height: "1rem", width: "1rem", marginRight: "0.5rem" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </Form.Label>
        <Form.Control
          size="sm"
          type={type}
          isInvalid={isInvalid}
          placeholder={_.capitalize(placeholder)}
          onChange={handleFileChnage}
        />
        <Form.Control.Feedback type="invalid">
          {_.capitalize(err || "")}
        </Form.Control.Feedback>
      </Form.Group>
    );
  },
  isEqual
);
