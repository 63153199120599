import { useToggle } from "@react-hookz/web";
import { Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { CustTable } from "../../Componenet/CustTable";
import Loader from "../../Componenet/Loader";
import {
  AdminUpdatePasswrodInput,
  CreateUserInputType,
  UpdateUserInputType,
  useAdminUpPasswordMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetAllUserQuery,
  useUpdateUserMutation,
} from "../../gql";

const initialValue: CreateUserInputType = {
  userName: "",
  email: "",
  password: "",
  isActive: true,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  userName: Yup.string().required(),
  password: Yup.string().required(),
  isActive: Yup.boolean().oneOf([true, false]).required(),
});

const AddStandard: React.FC<{
  isToggled: boolean;
  toggle: () => void;
  refetch: () => void;
}> = ({ isToggled, toggle, refetch }) => {
  const [create] = useCreateUserMutation();

  const handleSubmit = async (
    val: CreateUserInputType,
    actions: FormikHelpers<CreateUserInputType>
  ) => {
    actions.setSubmitting(true);

    try {
      await create({ variables: { options: val } });
      refetch();
      actions.resetForm();
      toast.success("Data added successfully");
      toggle();
    } catch (err) {
      toast.error("Trouble posting data");
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal show={isToggled} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Add Employee</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Form.FloatingLabel
                    label="Employee name"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee name"
                      name="userName"
                      value={values.userName}
                      isInvalid={!!touched.userName && !!errors.userName}
                    />
                  </Form.FloatingLabel>

                  <Form.FloatingLabel
                    label="Email"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee email"
                      name="email"
                      value={values.email}
                      isInvalid={!!touched.email && !!errors.email}
                    />
                  </Form.FloatingLabel>

                  <Form.FloatingLabel
                    label="Password"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee password"
                      name="password"
                      value={values.password}
                      isInvalid={!!touched.password && !!errors.password}
                    />
                  </Form.FloatingLabel>

                  <Form.Check
                    label="is Active"
                    checked={values.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    onChange={() => {
                      setFieldValue("isActive", !values.isActive);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggle}>
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const EditStandard: React.FC<{
  isToggled: boolean;
  toggle: () => void;
  refetch: () => void;
  data: UpdateUserInputType;
  _id: string;
}> = ({ isToggled, toggle, refetch, data, _id }) => {
  const [update] = useUpdateUserMutation();

  const handleSubmit = async (
    val: UpdateUserInputType,
    actions: FormikHelpers<UpdateUserInputType>
  ) => {
    actions.setSubmitting(true);

    try {
      await update({
        variables: {
          options: {
            _id,
            isActive: val.isActive,
            email: val.email,
            password: val.password,
            userName: val.userName,
          },
        },
      });
      refetch();
      actions.resetForm();
      toast.success("Data updated successfully");
      toggle();
    } catch (err) {
      toast.error("Trouble updating data");
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal show={isToggled} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          ...data,
          // @ts-ignore
          password: data.hash,
          isActive: data.isActive,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Modal.Body>
                  <Form.FloatingLabel
                    label="Employee name"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee name"
                      name="userName"
                      value={values.userName}
                      isInvalid={!!touched.userName && !!errors.userName}
                    />
                  </Form.FloatingLabel>

                  <Form.FloatingLabel
                    label="Email"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee email"
                      name="email"
                      value={values.email}
                      isInvalid={!!touched.email && !!errors.email}
                    />
                  </Form.FloatingLabel>

                  <Form.FloatingLabel
                    label="Password"
                    controlId="floatingInput"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Employee password"
                      name="password"
                      value={values.password}
                      isInvalid={!!touched.password && !!errors.password}
                      disabled={true}
                    />
                  </Form.FloatingLabel>

                  <Form.Check
                    label="is Active"
                    checked={values.isActive}
                    isInvalid={!!touched.isActive && !!errors.isActive}
                    onChange={() => {
                      setFieldValue("isActive", !values.isActive);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggle}>
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const initV1: AdminUpdatePasswrodInput = {
  newPassword: "",
  userId: "",
};

const validationSchemav1 = Yup.object().shape({
  newPassword: Yup.string().required(),
  userId: Yup.string(),
});

const ManagePasswordChange: FC<{
  isToggled: boolean;
  toggle: () => void;
  name: string;
  _id: string;
}> = ({ isToggled, toggle, name, _id }) => {
  const [create] = useAdminUpPasswordMutation();

  const handleSubmit = async (
    val: AdminUpdatePasswrodInput,
    actions: FormikHelpers<AdminUpdatePasswrodInput>
  ) => {
    actions.setSubmitting(true);
    try {
      const data = await create({
        variables: { options: { newPassword: val.newPassword, userId: _id } },
      });

      if (data.data?.adminUpPassword.isSuccess === true) {
        toast.success("password updated successfully");
        toggle();
      } else {
        toast.error("error updating password");
      }
    } catch (err) {
      toast.error("error updating password");
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal show={isToggled} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Update Password for {name}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initV1}
        validationSchema={validationSchemav1}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    name="newPassword"
                    isInvalid={!!touched.newPassword && !!errors.newPassword}
                    value={values.newPassword}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={toggle}>close</Button>
                <Button type="submit">Submit</Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const ManageActions: React.FC<{
  value: string;
  refetch: () => void;
  val: UpdateUserInputType;
  name: string;
}> = ({ value, refetch, val, name }) => {
  const [isToggled, toggle] = useToggle(false);
  const [isToggled2, toggle2] = useToggle(false);
  const [deleteQuery] = useDeleteUserMutation();

  const handleDelete = async () => {
    await deleteQuery({ variables: { options: { userId: value } } });
    toast.success("Item deleted successfully");
    refetch();
  };

  return (
    <>
      <EditStandard
        isToggled={isToggled}
        refetch={refetch}
        toggle={toggle}
        data={val}
        _id={value}
      />
      <ManagePasswordChange
        isToggled={isToggled2}
        toggle={toggle2}
        name={name}
        _id={value}
      />
      <div className="d-flex justify-content-center gap-2">
        <Button className="rounded-pill" onClick={toggle}>
          <i className="bi bi-pencil"></i>
        </Button>
        <Button className="rounded-pill" onClick={toggle2}>
          <i className="bi bi-key"></i>
        </Button>
        <Button
          className="rounded-pill"
          variant="danger"
          onClick={handleDelete}
        >
          <i className="bi bi-trash"></i>
        </Button>
      </div>
    </>
  );
};

const Employee = () => {
  const [isToggled, toggle] = useToggle(false);
  const { loading, refetch, data } = useGetAllUserQuery();

  const columns = useMemo(
    () => [
      { Header: "No", accessor: "id" },
      { Header: "Name", accessor: "userName" },
      { Header: "Email", accessor: "email" },
      {
        Header: "Is Admin",
        accessor: "isAdmin",
        Cell: (e: any) => {
          return <span>{e.value === true ? "Admin" : "Employee"}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: (e: any) => {
          return <span>{e.value === true ? "active" : "disable"}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: (e: any) => {
          return (
            <ManageActions
              name={e.row.original.userName as string}
              value={e.value as string}
              refetch={refetch}
              val={e.row.original}
            />
          );
        },
      },
    ],
    []
  );

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div>
      <AddStandard toggle={toggle} isToggled={isToggled} refetch={refetch} />
      <div className="d-flex align-items-center justify-content-between">
        <h4>Employee</h4>
        <Button onClick={toggle}>Add</Button>
      </div>
      {Array.isArray(data?.getAllUser.data) && (
        <CustTable
          columns={columns}
          data={data?.getAllUser.data.map((x, i) => ({ ...x, id: i + 1 }))}
        />
      )}
    </div>
  );
};

export default Employee;
