import { css } from "@emotion/css";
import { Spinner } from "react-bootstrap";

const style = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
`;

const Loader = () => {
  return (
    <div className={style}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
