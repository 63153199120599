import { Formik, FormikHelpers } from "formik";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import { map } from "modern-async";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { read, utils, writeFile } from "xlsx";
import * as Yup from "yup";
import { CustTable } from "../../Componenet/CustTable";
import Loader from "../../Componenet/Loader";
import {
  useDeleteContactPersonMutation,
  useGetAllContactPersonQuery,
  useGetAllUserQuery,
  useGetContactCategoryQuery,
  useImportContactPersonMutation,
} from "../../gql";
import { useAppState } from "../../store";
// @ts-ignore

interface excelInputType {
  srNo: any | null;
  nameOfStudent: string | null;
  address: string | null;
  contact1: string | null;
  contact2: string | null;
  prevSchool: string | null;
  prevTution: string | null;
}

const SR_NO = 0;
const NAME_OF_STUDENT = 1;
const ADDRESS = 2;
const CONTACT_1 = 3;
const CONTACT_2 = 4;
const PREVIOUS_SCHOOL = 5;
const PREVIOUS_TUITION = 6;

const initValue = { empName: "", contactCategory: "" };
const validationSchema = Yup.object().shape({
  empName: Yup.string().required(),
  contactCategory: Yup.string().required(),
});

const ManageActions: React.FC<{
  value: string;
  refetch: () => void;
}> = ({ value, refetch }) => {
  const [deletePerson] = useDeleteContactPersonMutation();

  const handleDelete = async () => {
    await deletePerson({ variables: { options: { _id: value } } });
    refetch();
    toast.success("deleted successsfully");
  };

  return (
    <>
      <div className="d-flex justify-content-center gap-2">
        <Link
          to={`/admin/conversation/${value}`}
          className="rounded-pill btn btn-primary"
          target="_blank"
        >
          <i className="bi bi-pencil"></i>
        </Link>
        <Button
          className="rounded-pill"
          variant="danger"
          onClick={handleDelete}
        >
          <i className="bi bi-trash"></i>
        </Button>
      </div>
    </>
  );
};

const ManageExport = () => {
  const { data } = useGetAllContactPersonQuery({ fetchPolicy: "no-cache" });

  const handleExport = () => {
    const arrData: string[][] = [];
    const headingArray: string[] = [
      "student name",
      "contact category",
      "contact 1",
      "contact 2",
      "address",
      "is admission confirmed",
      "assigned employee",
      "fee deliverd",
      "fee paid",
      "follow-up 1",
      "follow-up 2",
      "follow-up 3",
      "follow-up 4",
      "follow-up 5",
      "follow-up 6",
      "follow-up 7",
      "follow-up 8",
      "follow-up 9",
      "follow-up 10",
    ];

    arrData.push(headingArray);

    data?.getAllContactPerson.data.map((x) => {
      const followUpArr: string[] = [];
      x.followUp?.map((x) => followUpArr.push(x.message));

      arrData.push([
        x.name || "",
        x.contactCategory?.name || "",
        x.contact1 || "",
        x.contact2 || "",
        x.address || "",
        x.admissionConfirmed === true ? "YES" : "NO",
        x.assignedUser.userName || "",
        x.feeDeliverd || "",
        x.feePaid || "",
        ...followUpArr,
      ]);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(workbook, "export" + Date.now().toString() + ".xlsx");
  };

  return (
    <div>
      <Button variant="success" onClick={handleExport}>
        EXPORT
      </Button>
    </div>
  );
};

const ManageImportXLSX: React.FC<{ refetch: () => void }> = ({ refetch }) => {
  const [excelData, setExeclData] = useState<excelInputType[]>();
  const [create] = useImportContactPersonMutation();
  const [init, setInit] = useState(initValue);
  const { isAdmin, jwt } = useAppState();
  const { data: user } = useGetAllUserQuery();
  const { data: contactCategory } = useGetContactCategoryQuery();

  useEffect(() => {
    if (isAdmin === false) {
      if (jwt) {
        const { _id } = jwtDecode(jwt) as { _id?: string };
        if (_id) {
          setInit({ empName: _id, contactCategory: "" });
        }
      }
    }
  }, [isAdmin, jwt]);

  const handleFileChange = (e: any) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        // @ts-ignore
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var result = utils.sheet_to_json(firstSheet, { header: 1 });

        const arr: excelInputType[] = [];

        result.map((x) => {
          Array.isArray(x) &&
            arr.push({
              srNo: x[SR_NO],
              nameOfStudent: x[NAME_OF_STUDENT],
              address: x[ADDRESS],
              contact1: x[CONTACT_1],
              contact2: x[CONTACT_2],
              prevSchool: x[PREVIOUS_SCHOOL],
              prevTution: x[PREVIOUS_TUITION],
            });
        });

        const filterdData = arr.filter(
          (x) =>
            !(
              _.isEmpty(x.address) &&
              _.isEmpty(x.contact1) &&
              _.isEmpty(x.contact2) &&
              _.isEmpty(x.nameOfStudent) &&
              _.isEmpty(x.prevSchool) &&
              _.isEmpty(x.prevTution) &&
              _.isEmpty(x.srNo)
            )
        );

        setExeclData(filterdData.slice(1));
      };
      // @ts-ignore
      reader.readAsArrayBuffer(e.target.files[0]);

      toast.success("Successfully read data from file");
    } catch (err) {
      toast.error("Please upload valid file");
    }
  };

  const handleSubmit = async (
    val: typeof initValue,
    actions: FormikHelpers<typeof initValue>
  ) => {
    actions.setSubmitting(true);
    let isSuccess = false;

    const custToast = toast.loading("Processing data");

    if (excelData && excelData.length !== 0) {
      await map(excelData, async (x) => {
        try {
          await create({
            variables: {
              options: {
                address: _.toString(x.address || ""),
                contact1: _.toString(x.contact1 || ""),
                contact2: _.toString(x.contact2 || ""),
                empName: _.toString(val.empName),
                nameOfStudent: _.toString(x.nameOfStudent || ""),
                prevSchool: _.toString(x.prevSchool || ""),
                prevTution: _.toString(x.prevTution || ""),
                contactCategory: _.toString(val.contactCategory),
              },
            },
          });
        } catch (err) {}
      });
      refetch();
      isSuccess = true;
    } else {
      toast.error("please upload excel");
      isSuccess = false;
    }
    actions.setSubmitting(false);

    if (isSuccess === true) {
      toast.success("Data is imported", { id: custToast });
    } else {
      toast.error("trouble importing data", { id: custToast });
    }
  };

  return (
    <div>
      <Formik
        initialValues={init}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => {
          return (
            <div>
              <Form onChange={handleChange} onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formik.contactCategory"
                    >
                      <Form.Label>
                        Contact Category <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        aria-label="Contact Category"
                        name={"contactCategory"}
                        value={values.contactCategory}
                        isInvalid={
                          !!touched.contactCategory && !!errors.contactCategory
                        }
                      >
                        <option value={""}>select contact category</option>
                        {Array.isArray(
                          contactCategory?.getContactCategory.data
                        ) &&
                          contactCategory.getContactCategory.data
                            .filter((x) => x.isActive === true)
                            .map((x) => {
                              return <option value={x._id}>{x.name}</option>;
                            })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formik.assignedEmployee"
                    >
                      <Form.Label>
                        Assign Employee <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        aria-label="Assign Employee"
                        name={"empName"}
                        value={values.empName}
                        isInvalid={!!touched.empName && !!errors.empName}
                        disabled={!isAdmin}
                      >
                        <option>select employee</option>
                        {Array.isArray(user?.getAllUser.data) &&
                          user?.getAllUser.data
                            .filter((x) => x.isActive === true)
                            .filter((x) => x.isAdmin !== true)
                            .map((x) => {
                              return (
                                <option value={x._id}>
                                  {x.userName} - {x.email}
                                </option>
                              );
                            })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="formik.excel" className="mb-3">
                      <Form.Label>
                        Excel <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        size="sm"
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="submit">IMPORT</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

const Conversation = () => {
  const { data, loading, refetch } = useGetAllContactPersonQuery();
  const { data: contactCategory } = useGetContactCategoryQuery();
  const { push } = useHistory();
  const [contCate, setContCate] = useState("");

  const columns = useMemo(
    () => [
      { Header: "No", accessor: "id" },
      {
        Header: "Name",
        accessor: "name",
        Cell: (e: any) => {
          return (
            <div>
              <p>
                <span className="fw-bold">Name</span> : {e.row.original.name}
              </p>
              <p>
                <span className="fw-bold">contact 1</span> :{" "}
                {e.row.original.contact1}
              </p>
              <p>
                <span className="fw-bold">contact 2</span> :{" "}
                {e.row.original.contact2}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Contact Category",
        accessor: "contactCategory",
        Cell: (e) => {
          return <div className="pt-2">{e?.value?.name || ""}</div>;
        },
      },
      { Header: "Address", accessor: "address" },
      { Header: "Pre School", accessor: "preSchool" },
      { Header: "Assigned Employee", accessor: "assignedUser.userName" },
      {
        Header: "Admission Confirmed",
        accessor: "admissionConfirmed",
        Cell: (e) => {
          return (
            <div className="pt-2">
              {e.value === true && <i className="bi bi-check"></i>}
              {e.value === false && <i className="bi bi-x"></i>}
            </div>
          );
        },
      },
      {
        Header: "Follow-up",
        accessor: "followUp",
        Cell: (e: any) => {
          return (
            <div>
              <p>{Array.isArray(e.value) ? e.value.length : 0}</p>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: (e: any) => {
          return <ManageActions value={e.value as string} refetch={refetch} />;
        },
      },
    ],
    []
  );

  const handleRedirect = () => {
    push("/admin/conversation/add");
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div>
      <div>
        <Card>
          <Card.Header>
            <Row>
              <Col>
                <div>
                  <h4>Conversation</h4>
                </div>
              </Col>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ display: "flex", gap: "1rem" }}>
                  <ManageExport />
                  <div>
                    <Button variant="success" onClick={handleRedirect}>
                      ADD
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <div>
              <ManageImportXLSX refetch={refetch} />
            </div>
          </Card.Body>
        </Card>
      </div>

      <br />

      <div>
        <Card>
          <Card.Header>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Select Contact Category</Form.Label>
                  <Form.Select
                    name="contactCategory"
                    size="sm"
                    onChange={(e) => {
                      setContCate(e.target.value);
                    }}
                  >
                    <option value="">select</option>
                    {Array.isArray(contactCategory?.getContactCategory.data) &&
                      contactCategory?.getContactCategory.data
                        .filter((x) => x.isActive === true)
                        .map((x) => {
                          return <option value={x._id}>{x.name}</option>;
                        })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              {contCate ? (
                <div>
                  <CustTable
                    columns={columns}
                    data={data?.getAllContactPerson.data
                      .filter((x) => x.contactCategory?._id === contCate)
                      .map((x, i) => ({
                        ...x,
                        id: i + 1,
                      }))}
                  />
                </div>
              ) : (
                <div>
                  <CustTable
                    columns={columns}
                    data={data?.getAllContactPerson.data.map((x, i) => ({
                      ...x,
                      id: i + 1,
                    }))}
                  />
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>

      <br />

      <div></div>
    </div>
  );
};

export default Conversation;
