import CustomInportedTable from "./customTable/table";

const CustTable: React.FC<{
  columns: any;
  data: any;
}> = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  // @ts-ignore
  return <CustomInportedTable columnInfo={columns} columnData={data} />;
};

export { CustTable };
