import { useToggle } from "@react-hookz/web";
import { Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { CustTable } from "../../Componenet/CustTable";
import { CustomFileInput } from "../../Componenet/CustomFilePicker";
import { API_URL } from "../../constants";
import {
  GetContactPersonInput,
  GetFllowUpByIdQuery,
  useAddFollowupMutation,
  useDeleteFollowUpByIdMutation,
  useGetAllUserQuery,
  useGetBranchQuery,
  useGetContactCategoryQuery,
  useGetContactPersonByIdLazyQuery,
  useGetFllowUpByIdLazyQuery,
  useGetStandardQuery,
  useUpdateContactPersonMutation,
} from "../../gql";
import { useAppState } from "../../store";

const initValue: GetContactPersonInput = {
  address: "",
  admissionConfirmed: false,
  assignedUser: "",
  cancleReasone: "",
  contact1: "",
  contact2: "",
  feeDeliverd: "",
  feePaid: "",
  isActive: false,
  name: "",
  preSchool: "",
  preTution: "",
  refrence: "",
  standard: "",
  branch: "",
  contactCategory: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string(),
  admissionConfirmed: Yup.boolean().oneOf([true, false]).required(),
  assignedUser: Yup.string().required(),
  cancleReasone: Yup.string(),
  contact1: Yup.string().min(10).max(10).required(),
  contact2: Yup.string().min(10).max(10),
  feeDeliverd: Yup.string(),
  feePaid: Yup.string(),
  isActive: Yup.boolean().oneOf([true, false]).required(),
  preSchool: Yup.string(),
  preTution: Yup.string(),
  refrence: Yup.string(),
  standard: Yup.string().required(),
  branch: Yup.string().required(),
  contactCategory: Yup.string().required(),
});

const initValueV2 = {
  date: "",
  optionValue: "1",
  responseType: "1",
  message: "",
  followUpImage: "",
};
const validationSchemaV2 = Yup.object().shape({
  message: Yup.string(),
  date: Yup.string(),
  optionValue: Yup.string(),
  responseType: Yup.string(),
  followUpImage: Yup.string().required(),
});

type iv = typeof initValueV2;

const RenderDeleteButton: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const [deleteFollowUp] = useDeleteFollowUpByIdMutation();

  const handleDelete = async () => {
    const data = await deleteFollowUp({ variables: { options: { _id: id } } });

    if (data.data?.deleteFollowUpById.isSuccess === true) {
      toast.success("Follow-up deleted successfully");
      refetch();
    } else {
      toast.error("Trouble deleting items");
    }
  };

  return (
    <div>
      <Button className="rounded-pill" onClick={handleDelete}>
        <i className="bi bi-trash"></i>
      </Button>
    </div>
  );
};

const RenderFollowUp: React.FC<{ id: string }> = ({ id }) => {
  const [optValue, setOptValue] = useState<string>("0");
  const [list, setList] = useState<GetFllowUpByIdQuery | undefined | null>();
  const [get, { refetch }] = useGetFllowUpByIdLazyQuery({
    onCompleted: (val) => setList(val),
  });
  const [create] = useAddFollowupMutation();
  const parsedUrl = new URL(API_URL);
  const [isToggled, toggle] = useToggle(false);
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    (async () => {
      if (id) {
        await get({ variables: { options: { _id: id } } });
      }
    })();
  }, []);

  const columns = useMemo(
    () => [
      { Header: "No", accessor: "id" },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Date",
        accessor: "followUpDate",
        Cell: (e: any) => {
          return (
            <>
              <div>
                {moment(e.row.original.followUpDate).format("DD/MM/YYYY")}
              </div>
            </>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: (e: any) => {
          return (
            <>
              <div className="d-flex gap-2">
                <RenderDeleteButton id={e.value as string} refetch={refetch} />
                <div>
                  <Button
                    className="rounded-pill"
                    onClick={() => {
                      setImgUrl(e.row.original.followUpImage as string);
                      toggle();
                    }}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </Button>
                </div>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const columnsTwo = useMemo(
    () => [
      { Header: "No", accessor: "id" },
      {
        Header: "Response",
        accessor: "responseType",
        Cell: (e: any) => {
          return (
            <>
              {e.row.original.value}
              <div>
                {e.row.original.responseType === "1" ? "Postive" : "Negative"}
              </div>
            </>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: (e: any) => {
          return (
            <>
              <div className="d-flex gap-2">
                <RenderDeleteButton id={e.value as string} refetch={refetch} />
                <div>
                  <Button
                    className="rounded-pill"
                    onClick={() => {
                      setImgUrl(e.row.original.followUpImage as string);
                      toggle();
                    }}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </Button>
                </div>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const handleSubmit = async (val: iv, actions: FormikHelpers<iv>) => {
    actions.setSubmitting(true);
    console.log(val);

    if (val.optionValue === "1") {
      if (val.date === "") {
        toast.error("Please select date");
        return;
      }
      if (val.message === "") {
        toast.error("Please enter follow up message");
        return;
      }
    }

    try {
      await create({
        variables: {
          options: {
            contactPersonId: id,
            optionType: val.optionValue,
            responseType: val.responseType,
            followUpDate: val.date,
            message: val.message,
            followUpImage: val.followUpImage,
          },
        },
      });

      if (val.optionValue === "1") {
        toast.success("follow up added successfully");
      } else {
        toast.success("response added successfully");
      }
      actions.resetForm();
    } catch (err) {
      console.log(err);
      toast.error("Trouble creating changes");
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <div>
            <h5>Follow/Closing Details</h5>
          </div>
        </Card.Header>

        <Card.Body>
          <div>
            <Formik
              initialValues={initValueV2}
              validationSchema={validationSchemaV2}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleSubmit,
                touched,
                errors,
                values,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <div>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formik.option"
                          >
                            <Form.Label>
                              Option
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                              size="sm"
                              aria-label="option"
                              name="optionValue"
                              value={values.optionValue}
                              isInvalid={
                                !!touched.optionValue && !!errors.optionValue
                              }
                            >
                              <option value={0}>Response</option>
                              <option value={1}>Follow Up</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        {values.optionValue === "0" && (
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formik.assignedEmployee"
                            >
                              <Form.Label>
                                Response Type
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Select
                                size="sm"
                                aria-label="option"
                                name="responseType"
                                value={values.responseType}
                                isInvalid={
                                  !!touched.responseType &&
                                  !!errors.responseType
                                }
                              >
                                <option value={1}>Positive</option>
                                <option value={0}>Negative</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        )}

                        {values.optionValue === "1" && (
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formik.date"
                            >
                              <Form.Label>
                                Date
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                size="sm"
                                aria-label="option"
                                type="date"
                                placeholder="Select Date"
                                name="date"
                                value={values.date}
                                isInvalid={!!touched.date && !!errors.date}
                                required={
                                  values.optionValue === "1" ? true : false
                                }
                              />
                            </Form.Group>
                          </Col>
                        )}

                        {values.optionValue === "1" && (
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formik.assignedEmployee"
                            >
                              <Form.Label>
                                Message
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                size="sm"
                                aria-label="option"
                                type="text"
                                placeholder="Enter follow-up"
                                name="message"
                                value={values.message}
                                isInvalid={
                                  !!touched.message && !!errors.message
                                }
                                required={
                                  values.optionValue === "1" ? true : false
                                }
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col>
                          <CustomFileInput
                            err={errors.followUpImage}
                            isInvalid={
                              !!touched.followUpImage && !!errors.followUpImage
                            }
                            label="image"
                            name="followUpImage"
                            placeholder="Enter image"
                            value={values.followUpImage}
                            setFieldValue={setFieldValue}
                            isImage={true}
                            isPdf={false}
                            isRequired={true}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            <Button type="submit" disabled={isSubmitting}>
                              ADD
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </Card.Body>
      </Card>

      <br />

      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Select Option</Form.Label>
                <Form.Select
                  name="optValue"
                  size="sm"
                  onChange={(e) => {
                    setOptValue(e.target.value);
                  }}
                >
                  <option value={0}>Response</option>
                  <option value={1}>Follow Up</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Modal show={isToggled} onHide={toggle} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img
                className="img-fluid"
                src={`${parsedUrl.origin}/upload/${imgUrl}`}
                alt="image not found"
              />
            </Modal.Body>
          </Modal>

          {optValue === "0" ? (
            <div>
              {Array.isArray(list?.getFllowUpById.data) && (
                <CustTable
                  columns={columnsTwo}
                  data={list?.getFllowUpById.data
                    .filter((x) => x.optionType === "0")
                    .map((x, i) => ({ ...x, id: i + 1 }))}
                />
              )}
            </div>
          ) : (
            <div>
              {Array.isArray(list?.getFllowUpById.data) && (
                <CustTable
                  columns={columns}
                  data={list?.getFllowUpById.data
                    .filter((x) => x.optionType === "1")
                    .map((x, i) => ({ ...x, id: i + 1 }))}
                />
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

const Update = () => {
  const { pathname } = useLocation();

  const [personInfo, setPersonInfo] =
    useState<GetContactPersonInput>(initValue);

  const { data: standardData } = useGetStandardQuery();
  const { data: user } = useGetAllUserQuery();
  const { data: branch } = useGetBranchQuery();
  const { data: contactCategory } = useGetContactCategoryQuery();
  const { isAdmin } = useAppState();

  const [update] = useUpdateContactPersonMutation();

  const [get] = useGetContactPersonByIdLazyQuery({
    onCompleted: (val) => {
      setPersonInfo({
        address: val.getContactPersonById.data?.address || "",
        admissionConfirmed:
          val.getContactPersonById.data?.admissionConfirmed || false,
        assignedUser: val.getContactPersonById.data?.assignedUser?._id || "",
        cancleReasone: val.getContactPersonById.data?.cancleReasone || "",
        contact1: val.getContactPersonById.data?.contact1 || "",
        contact2: val.getContactPersonById.data?.contact2 || "",
        feeDeliverd: val.getContactPersonById.data?.feeDeliverd || "",
        feePaid: val.getContactPersonById.data?.feePaid || "",
        isActive: val.getContactPersonById.data?.isActive || false,
        name: val.getContactPersonById.data?.name || "",
        preSchool: val.getContactPersonById.data?.preSchool || "",
        preTution: val.getContactPersonById.data?.preTution || "",
        refrence: val.getContactPersonById.data?.refrence || "",
        standard: val.getContactPersonById.data?.standard?._id || "",
        branch: val.getContactPersonById.data?.branch?._id || "",
        contactCategory:
          val.getContactPersonById.data?.contactCategory?._id || "",
      });
    },
  });

  const { goBack } = useHistory();

  useEffect(() => {
    if (pathname)
      get({
        variables: { options: { _id: pathname.split("/").pop() || "" } },
      });
  }, []);

  const handleSubmit = async (
    val: GetContactPersonInput,
    actions: FormikHelpers<GetContactPersonInput>
  ) => {
    actions.setSubmitting(true);

    try {
      const data = await update({
        variables: {
          options: { ...val, _id: pathname.split("/").pop() || "" },
        },
      });

      if (data.data?.updateContactPerson.isSuccess === true) {
        toast.success("Data is updated successfully");
        goBack();
      } else {
        toast.error("Trouble posting data");
      }
    } catch (err) {
      toast.error("Trouble posting data");
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <div>
        <Card>
          <Card.Header>
            <div>
              <h5>Update</h5>
            </div>
          </Card.Header>

          <Card.Body>
            <div>
              <Formik
                initialValues={personInfo}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.FloatingLabel
                            label="Contact Category"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Select
                              size="sm"
                              name="contactCategory"
                              value={values.contactCategory}
                              isInvalid={
                                !!touched.contactCategory &&
                                !!errors.contactCategory
                              }
                            >
                              <option value="">select</option>
                              {Array.isArray(
                                contactCategory?.getContactCategory.data
                              ) &&
                                contactCategory?.getContactCategory.data
                                  .filter((x) => x.isActive === true)
                                  .map((x) => {
                                    return (
                                      <option value={x._id}>{x.name}</option>
                                    );
                                  })}
                            </Form.Select>
                          </Form.FloatingLabel>
                        </Col>

                        <Col>
                          <Form.FloatingLabel
                            label="Name"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter name"
                              name="name"
                              value={values.name}
                              isInvalid={!!touched.name && !!errors.name}
                            />
                          </Form.FloatingLabel>
                        </Col>

                        <Col>
                          <Form.FloatingLabel
                            label="Address"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter address"
                              name="address"
                              value={values.address}
                              isInvalid={!!touched.address && !!errors.address}
                            />
                          </Form.FloatingLabel>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.FloatingLabel
                            label="Pre school"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter pre school"
                              name="preSchool"
                              value={values.preSchool}
                              isInvalid={
                                !!touched.preSchool && !!errors.preSchool
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Pre Tuition"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter pre tuition"
                              name="preTution"
                              value={values.preTution}
                              isInvalid={
                                !!touched.preTution && !!errors.preTution
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Contact 1"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="number"
                              maxLength={10}
                              placeholder="Enter contact 1"
                              name="contact1"
                              value={values.contact1}
                              isInvalid={
                                !!touched.contact1 && !!errors.contact1
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.FloatingLabel
                            label="Contact 2"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="number"
                              maxLength={10}
                              placeholder="Enter contact 2"
                              name="contact2"
                              value={values.contact2}
                              isInvalid={
                                !!touched.contact2 && !!errors.contact2
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Branch"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Select
                              size="sm"
                              name="branch"
                              value={values.branch}
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value="">select</option>
                              {Array.isArray(branch?.getBranch.data) &&
                                branch?.getBranch.data
                                  .filter((x) => x.isActive === true)
                                  .map((x) => {
                                    return (
                                      <option value={x._id}>{x.name}</option>
                                    );
                                  })}
                            </Form.Select>
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Standard"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Select
                              size="sm"
                              name="standard"
                              value={values.standard}
                              isInvalid={
                                !!touched.standard && !!errors.standard
                              }
                            >
                              <option value="">select</option>
                              {Array.isArray(standardData?.getStandard.data) &&
                                standardData?.getStandard.data
                                  .filter((x) => x.isActive === true)
                                  .map((x) => {
                                    return (
                                      <option value={x._id}>{x.name}</option>
                                    );
                                  })}
                            </Form.Select>
                          </Form.FloatingLabel>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.FloatingLabel
                            label="Assign Employee"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Select
                              size="sm"
                              name="assignedUser"
                              value={values.assignedUser}
                              isInvalid={
                                !!touched.assignedUser && !!errors.assignedUser
                              }
                              disabled={!isAdmin}
                            >
                              <option value="">select</option>
                              {Array.isArray(user?.getAllUser.data) &&
                                user?.getAllUser.data
                                  .filter((x) => x.isActive === true)
                                  .filter((x) => x.isAdmin !== true)
                                  .map((x) => {
                                    return (
                                      <option value={x._id}>
                                        {x.userName} - {x.email}
                                      </option>
                                    );
                                  })}
                            </Form.Select>
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Fees Delivered"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter fee delivered"
                              name="feeDeliverd"
                              value={values.feeDeliverd}
                              isInvalid={
                                !!touched.feeDeliverd && !!errors.feeDeliverd
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Fees Paid"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Fees paid"
                              name="feePaid"
                              value={values.feePaid}
                              isInvalid={!!touched.feePaid && !!errors.feePaid}
                            />
                          </Form.FloatingLabel>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.FloatingLabel
                            label="Reference"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter reference"
                              name="refrence"
                              value={values.refrence}
                              isInvalid={
                                !!touched.refrence && !!errors.refrence
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <Form.FloatingLabel
                            label="Cancel Reason"
                            controlId="floatingInput"
                            className="mb-3"
                          >
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="Enter reason"
                              name="cancleReasone"
                              value={values.cancleReasone}
                              isInvalid={
                                !!touched.cancleReasone &&
                                !!errors.cancleReasone
                              }
                            />
                          </Form.FloatingLabel>
                        </Col>
                        <Col>
                          <div>
                            <Form.Check
                              checked={values.admissionConfirmed}
                              label="Is Admission Confirmed"
                              onClick={() =>
                                setFieldValue(
                                  "admissionConfirmed",
                                  !values.admissionConfirmed
                                )
                              }
                            />

                            <Form.Check
                              checked={values.isActive}
                              label="Is Closed"
                              onClick={() =>
                                setFieldValue("isActive", !values.isActive)
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            <Button type="submit" disabled={isSubmitting}>
                              UPDATE
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div></div>

      <br />

      <RenderFollowUp id={pathname.split("/").pop() || ""} />

      <br />

      <div></div>
    </div>
  );
};

export default Update;
