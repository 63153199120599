import { Button, Col, Row, Table } from "react-bootstrap";

import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// import "../table/filtering.css";
import reverse from "lodash/reverse";

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <Row>
      <Col>
        <div
          className="table-search ms-3"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className="me-2">Search :</span>
          <input
            className="ml-2 input-search form-control"
            value={filter || ""}
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "20%" }}
          />
        </div>
      </Col>
    </Row>
  );
};

export const updateData = () => {
  return <Button>Update</Button>;
};

export const deleteData = () => {
  return <Button>Update</Button>;
};

const DataTable = ({
  isLoading,
  columnInfo,
  columnData,
  tableTitle,
  bText,
  handleExcel,
  redirectText,
  handleRedirect,
}) => {
  const data = React.useMemo(
    () =>
      Array.isArray(columnData)
        ? reverse(columnData).map((x, i) => ({
            ...x,
            id: i + 1,
          }))
        : [],
    [columnData]
  );
  const columns = React.useMemo(
    () => (Array.isArray(columnInfo) ? columnInfo : []),
    [columnInfo]
  );

  // const { push } = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  const handleButtonPress = () => {
    //TODO: Impliment Later
    // push("?action=add");
  };

  if (isLoading === true) {
    return <></>;
  }

  return (
    <div>
      <div>
        <div className="table-responsive">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

          <Table
            {...getTableProps()}
            className="display w-100 dataTable "
            role="grid"
            bordered
          >
            <thead
              style={{
                borderTop: "1px solid #e2e5e9",
                borderBottom: "1px solid #e2e5e9",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            class="fa fa-sort-desc ms-3"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i class="fa fa-sort-asc ms-3" aria-hidden="true"></i>
                        )
                      ) : (
                        <i class="fa fa-sort ms-3" aria-hidden="true"></i>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} role="row">
                    {row.cells.map((cell, i) => {
                      return (
                        <>
                          <td
                            {...cell.getCellProps()}
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                style={{ color: "black" }}
                type="number"
                className="ml-2"
                value={pageIndex + 1}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div
              className="filter-pagination  mt-3"
              style={{ backgroundColor: "white" }}
            >
              <button
                style={{ fontSize: "15px", color: "black" }}
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                style={{ fontSize: "15px", color: "black" }}
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                style={{ fontSize: "15px", color: "black" }}
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                style={{ fontSize: "15px", color: "black" }}
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableSvg = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

export { TableSvg };

export default DataTable;
