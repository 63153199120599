import "../dashboard.css";
import { useGetCountQuery } from "../gql";

const Dashboard = () => {
  const { data } = useGetCountQuery();

  return (
    <div className="row gap-2">
      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">{data?.getCount.data.totalInquiry}</div>
                <div className="under-number">Total inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">{data?.getCount.data.admissionCount}</div>
                <div className="under-number">Completed inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">
                  {data?.getCount.data.workingInquiery}
                </div>
                <div className="under-number">Running inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">
                  {data?.getCount.data.admissionFailed}
                </div>
                <div className="under-number">Cancelled inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">{data?.getCount.data.walkInInquiry}</div>
                <div className="under-number">Walk In inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 border border-2">
        <div className="panel">
          <div className="panel-heading bg-light text-black">
            <div className="row">
              <div className="text-right">
                <div className="huge">
                  {" "}
                  {(data?.getCount.data.totalInquiry || 0) -
                    (data?.getCount.data.walkInInquiry || 0)}
                </div>
                <div className="under-number">Normal inquiries</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
