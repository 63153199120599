import { css } from "@emotion/css";
import { Formik, FormikHelpers } from "formik";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { UserInputType, useAuthMutation } from "../gql";
import { useAppState } from "../store";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(8).max(18).required(),
});

const initialValue: UserInputType = { email: "", password: "" };

const rowStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
`;

const Login = () => {
  const [create] = useAuthMutation();
  const { isAuth, setAuth } = useAppState();
  const { push } = useHistory();

  useEffect(() => {
    if (isAuth === true) {
      push("/dashboard");
    }
  }, [isAuth]);

  const handleSubmit = async (
    val: UserInputType,
    action: FormikHelpers<UserInputType>
  ) => {
    action.setSubmitting(true);

    try {
      const data = await create({ variables: { options: { ...val } } });

      if (data.data?.auth.isSuccess === true) {
        setAuth({
          isAdmin: data.data.auth.data.isAdmin,
          jwt: data.data.auth.data.jwt,
          username: data.data.auth.data.userName,
        });

        toast.success("Successfully logged in");

        push("/dashboard");
      }

      if (data.data?.auth.isSuccess === false) {
        toast.error("Authentication error");
      }
    } catch (err) {
      toast.error("Authentication error");
    }

    action.setSubmitting(false);
  };

  return (
    <Container>
      <Row className={rowStyle}>
        <Col md="4">
          <main className="form-signin w-100 m-auto text-center">
            <img
              src="http://scpledu.com/assets/images/logo/logo-dark.png"
              className="mb-4"
              alt="logo image not found"
            />

            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Form.FloatingLabel
                      label="Email address"
                      controlId="floatingInput"
                      className="mb-2"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Email Address"
                        name="email"
                        value={values.email}
                        isInvalid={!!touched.email && !!errors.email}
                      />
                    </Form.FloatingLabel>

                    <Form.FloatingLabel
                      label="Password"
                      controlId="floatingInput"
                      className="mb-2"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        value={values.password}
                        isInvalid={!!touched.password && !!errors.password}
                      />
                    </Form.FloatingLabel>

                    <div className="d-grid">
                      <Button type="submit" disabled={isSubmitting}>
                        Sign In
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </main>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
