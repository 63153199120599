import { useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import AppNavbar from "./Componenet/Navbar";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import Branch from "./screens/branch";
import Contacts from "./screens/contact";
import ContactCategory from "./screens/contact/category";
import Conversation from "./screens/conversation";
import ConversationAdd from "./screens/conversation/Add";
import ConversationUpdate from "./screens/conversation/Update";
import Employee from "./screens/employee";
import Standard from "./screens/standard";
import { useAppState } from "./store";

const Routes = () => {
  const { isAuth } = useAppState();
  const { pathname } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const isNotValidAuth =
      pathname.includes("admin") || pathname.includes("dashboard");

    if (isAuth === false && isNotValidAuth) {
      push("/");
    }
  }, [pathname, isAuth]);

  return (
    <>
      {(pathname.includes("admin") || pathname.includes("dashboard")) && (
        <AppNavbar />
      )}
      <Container>
        <Switch>
          <Route exact path={"/login"} component={Login} />
          <Route exact path={"/dashboard"} component={Dashboard} />
          <Route exact path={"/admin/branch"} component={Branch} />
          <Route exact path={"/admin/employee"} component={Employee} />
          <Route exact path={"/admin/standard"} component={Standard} />
          <Route exact path={"/admin/contacts"} component={Contacts} />
          <Route
            exact
            path={"/admin/contact/category"}
            component={ContactCategory}
          />
          <Route exact path={"/admin/conversation"} component={Conversation} />
          <Route
            exact
            path={"/admin/conversation/add"}
            component={ConversationAdd}
          />
          <Route
            exact
            path={"/admin/conversation/:id"}
            component={ConversationUpdate}
          />
          <Redirect to={isAuth ? "/dashboard" : "/login"} />
        </Switch>
      </Container>
    </>
  );
};

export default Routes;
