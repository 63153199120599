import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface IAuht {
  jwt: string;
  username: string;
  isAdmin: boolean;
}

interface AppState {
  isAuth: boolean;
  isAdmin: boolean;
  jwt: string;
  username: string;
  setAuth: (arg0: IAuht) => void;
  logout: () => void;
}

export const useAppState = create<AppState>()(
  persist(
    (set) => ({
      isAuth: false,
      isAdmin: false,
      jwt: "",
      username: "",
      setAuth: (payload) => {
        set(() => ({
          isAuth: true,
          username: payload.username,
          jwt: payload.jwt,
          isAdmin: payload.isAdmin,
        }));
      },
      logout: () => {
        set(() => ({
          isAuth: false,
          username: "",
          jwt: "",
          isAdmin: false,
        }));
      },
    }),
    {
      name: "app_state",
      version: 0.01,
      storage: createJSONStorage(() => localStorage),
    }
  )
);
