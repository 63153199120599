import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";

import { setContext } from "@apollo/client/link/context";
import { API_URL } from "./constants";
import { useAppState } from "./store";

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { jwt } = useAppState();

  const httpLink = createHttpLink({
    uri: API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: jwt,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Toaster position="top-center" reverseOrder={false} />
      <BrowserRouter>{children}</BrowserRouter>
    </ApolloProvider>
  );
};

export default AppProvider;
